import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Use BrowserRouter here
import Login from "components/auth/Login";
import Register from "components/auth/Register";
import EmailVerificationPage from "components/auth/EmailVerification";
import Dashboard from "components/dashboard/Dashboard";
import Settings from "components/dashboard/Settings";
import Todo from "components/todo/App";
//landing page
import LandingPage from "components/landingPage/App";
import Statue from "components/landingPage/statue/Statue";
import Rodo from "components/landingPage/rodo/Rodo";
import Contact from "components/landingPage/contact/Contact";
import Calendar from "components/calendar/App";
import Notes from "components/notes/App";
import ProtectedRoute from "ProtectedRoute";
import ReportBugPage from "components/dashboard/ReportBugPage";
import TermsOfUse from "components/landingPage/termsOfUse/TermsOfUse";

function App() {
  return (
    <Router>
      <Routes>
        {/*landing page*/}
        <Route path="/" element={<LandingPage />} />
        <Route path="/statue" element={<Statue />} />
        <Route path="/rodo" element={<Rodo />} />
        <Route path="/contact" element={<Contact />} />

        {/* auth */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify" element={<EmailVerificationPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        {/* apps */}
        <Route
          path="/notes"
          element={
            <ProtectedRoute>
              <Notes />
            </ProtectedRoute>
          }
        />

        <Route
          path="/calendar"
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/todo"
          element={
            <ProtectedRoute>
              <Todo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report-bug"
          element={
            <ProtectedRoute>
              <ReportBugPage />
            </ProtectedRoute>
          }
        />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
      </Routes>
    </Router>
  );
}

export default App;
