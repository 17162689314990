import React, { useState } from "react";
import DeleteModal from "./DeleteModal";
import ShareNotesModal from "./ShareNotesModal"; // Adjust the import path as necessary

function CategoryElipsisMenu({ type, onDelete, onEdit, currentCategory, setIsShareModalOpen }) {
    
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  
  const handleEditClick = () => {
    setIsCategoryModalOpen(true);
  };  

  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };

  if (currentCategory === null) {
    // Return null if no category is selected
    return null;
  }

  return (
    <div className={type === "Categories" ? "absolute top-16 right-3" : "absolute top-6 right-4"}>
      <div className="flex justify-end items-center">
        <div className="w-50 text-sm text-left z-50 font-inter font-medium shadow-md shadow-[#364e7e1a] bg-white space-y-4 py-5 px-4 rounded-lg h-auto pr-12" style={{ position: 'fixed', top: '110px', right: '10px' }}>
          <p onClick={onEdit} className="cursor-pointer text-black">
            Edit Category
          </p>
          <p onClick={setIsShareModalOpen} className="cursor-pointer text-black">
            Share {type}
          </p>
          <p onClick={handleDeleteClick} className="cursor-pointer" style={{ color: "#E10505" }}>
            Delete {type}
          </p>
        </div>
      </div>

      {isDeleteModalVisible && (
        <DeleteModal
          type={type}
          onDelete={() => {
            onDelete();
            setIsDeleteModalVisible(false);
          }}
          setIsDeleteModalOpen={setIsDeleteModalVisible}
        />
      )}
    </div>
  );
}

export default CategoryElipsisMenu;
