import boardsSlice from "./boardsSlice";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const jwt = Cookies.get("jwt");

export const replaceBoardAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  const board = state.boards.find((board) => board.isActive);
  boardData = boardData;
  dispatch(boardsSlice.actions.replaceBoard(boardData));
};

export const addBoardAction = (boardData) => async (dispatch, getState) => {
  boardData.columns = boardData.newColumns;
  const isActive = false;
  boardData.isActive = isActive;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      boardData,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const newBoard = { board: response.data };
    dispatch(boardsSlice.actions.addBoard(newBoard));
  } catch (error) {
    console.error("Error creating a new board:", error);
  }
};

export const editBoardAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  const board = state.boards.find((board) => board.isActive);
  boardData._id = board._id;
  boardData.columns = boardData.newColumns;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      boardData,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const updatedBoard = { board: response.data };
    dispatch(boardsSlice.actions.editBoard(boardData));
  } catch (error) {
    console.error("Error updating the board:", error);
  }
};

export const deleteBoardAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  const board = state.boards.find((board) => board.isActive);
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/todo/board`, {
      data: { _id: board._id },
      headers: { Authorization: `Bearer ${jwt}` },
    });
    dispatch(boardsSlice.actions.deleteBoard());
    dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));
  } catch (error) {
    console.error("Error deleting the board:", error);
  }
};

export const addTaskAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  let board = state.boards.find((board) => board.isActive);
  const tmpBoard = JSON.parse(JSON.stringify(board));
  const { title, status, description, subtasks, newColIndex } = boardData;
  let column = tmpBoard.columns.find((col, index) => index === newColIndex);
  const task = { title, description, subtasks, status };
  column.tasks.push(task);
  boardData._id = board._id;
  boardData.columns = tmpBoard.column;
  tmpBoard.isActive = false;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      tmpBoard,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const updatedBoard = response.data;

    dispatch(boardsSlice.actions.addTask(boardData));
  } catch (error) {
    console.error("Error updating the board:", error);
    const err = error.response.data.err;
    if (Array.isArray(err)) {
      return error.response.data.err.forEach((e) => {
        toast.error(e.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error(error.response.data.err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const editTaskAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  let board = state.boards.find((board) => board.isActive);
  const tmpBoard = JSON.parse(JSON.stringify(board));
  const {
    title,
    status,
    description,
    subtasks,
    prevColIndex,
    newColIndex,
    taskIndex,
  } = boardData;
  const column = tmpBoard.columns.find((col, index) => index === prevColIndex);
  const task = column.tasks.find((task, index) => index === taskIndex);
  task.title = title;
  task.status = status;
  task.description = description;
  task.subtasks = subtasks;
  tmpBoard.isActive = false;

  if (!(prevColIndex === newColIndex)) {
    column.tasks = column.tasks.filter((task, index) => index !== taskIndex);
    const newCol = tmpBoard.columns.find((col, index) => index === newColIndex);
    newCol.tasks.push(task);
  }
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      tmpBoard,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const updatedBoard = response.data;

    dispatch(boardsSlice.actions.editTask(boardData));
  } catch (error) {
    console.error("Error updating the board:", error);
    const err = error.response.data.err;
    if (Array.isArray(err)) {
      return error.response.data.err.forEach((e) => {
        toast.error(e.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error(error.response.data.err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const dragTaskAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  const board = state.boards.find((board) => board.isActive);
  const tmpBoard = JSON.parse(JSON.stringify(board));
  const { colIndex, prevColIndex, taskIndex } = boardData;
  const prevCol = tmpBoard.columns.find((col, i) => i === prevColIndex);
  const task = prevCol.tasks.splice(taskIndex, 1)[0];
  tmpBoard.columns.find((col, i) => i === colIndex).tasks.push(task);
  boardData._id = board._id;
  boardData.columns = tmpBoard.column;
  tmpBoard.isActive = false;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      tmpBoard,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const updatedBoard = response.data;

    dispatch(boardsSlice.actions.dragTask(boardData));
  } catch (error) {
    console.error("Error updating the board:", error);
    const err = error.response.data.err;
    if (Array.isArray(err)) {
      return error.response.data.err.forEach((e) => {
        toast.error(e.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error(error.response.data.err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const setSubtaskCompletedAction =
  (boardData) => async (dispatch, getState) => {
    const state = getState();
    const board = state.boards.find((board) => board.isActive);
    const tmpBoard = JSON.parse(JSON.stringify(board));
    const col = tmpBoard.columns.find((col, i) => i === boardData.colIndex);
    const task = col.tasks.find((task, i) => i === boardData.taskIndex);
    const subtask = task.subtasks.find((subtask, i) => i === boardData.index);
    subtask.isCompleted = !subtask.isCompleted;

    boardData._id = board._id;
    boardData.columns = tmpBoard.column;
    tmpBoard.isActive = false;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/todo/board`,
        tmpBoard,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      const updatedBoard = response.data;

      dispatch(boardsSlice.actions.setSubtaskCompleted(boardData));
    } catch (error) {
      console.error("Error updating the board:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

export const setTaskStatusAction =
  (boardData) => async (dispatch, getState) => {
    const state = getState();
    const board = state.boards.find((board) => board.isActive);
    const tmpBoard = JSON.parse(JSON.stringify(board));
    const columns = tmpBoard.columns;
    const col = columns.find((col, i) => i === boardData.colIndex);
    if (!(boardData.colIndex === boardData.newColIndex)) {
      const task = col.tasks.find((task, i) => i === boardData.taskIndex);
      task.status = boardData.status;
      col.tasks = col.tasks.filter((task, i) => i !== boardData.taskIndex);
      const newCol = columns.find((col, i) => i === boardData.newColIndex);
      newCol.tasks.push(task);
    }

    boardData._id = board._id;
    boardData.columns = tmpBoard.column;
    tmpBoard.isActive = false;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/todo/board`,
        tmpBoard,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      const updatedBoard = response.data;

      dispatch(boardsSlice.actions.setTaskStatus(boardData));
    } catch (error) {
      console.error("Error updating the board:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

export const deleteTaskAction = (boardData) => async (dispatch, getState) => {
  const state = getState();
  const board = state.boards.find((board) => board.isActive);
  const tmpBoard = JSON.parse(JSON.stringify(board));
  const col = tmpBoard.columns.find((col, i) => i === boardData.colIndex);
  col.tasks = col.tasks.filter((task, i) => i !== boardData.taskIndex);

  boardData._id = board._id;
  boardData.columns = tmpBoard.column;
  tmpBoard.isActive = false;
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      tmpBoard,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const updatedBoard = response.data;

    dispatch(boardsSlice.actions.deleteTask(boardData));
  } catch (error) {
    console.error("Error updating the board:", error);
    const err = error.response.data.err;
    if (Array.isArray(err)) {
      return error.response.data.err.forEach((e) => {
        toast.error(e.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error(error.response.data.err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export const fetchBoardsAction = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/todo/board`,
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    );
    const boardsData = response.data;
    //dispatch(setBoards(boardsData));
  } catch (error) {
    console.error("Error fetching boards:", error);
    const err = error.response.data.err;
    if (Array.isArray(err)) {
      return error.response.data.err.forEach((e) => {
        toast.error(e.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    } else {
      toast.error(error.response.data.err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};
