import React, { useState } from "react";
import Navbar from "../AppsNavbar";
import Cookies from "js-cookie";
import axios from "axios";
const ReportBugPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    description: "",
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();

    // data to send
    const dataToSend = new FormData();
    dataToSend.append("email", formData.email);
    dataToSend.append("description", formData.description);
    if (formData.file) {
      dataToSend.append("file", formData.file);
    }
    console.log(formData.file);
    console.log("Dane do formData:", formData);
    console.log(dataToSend);
    // Reset formularza

    try {
      const jwt = Cookies.get("jwt");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/bugreport`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
    } catch (error) {
      console.log(error);
    }

    setFormData({
      email: "",
      description: "",
      file: null,
    });
  };

  return (
    <div className="bg-white">
      <Navbar />
      <div className="container mx-auto p-4 flex justify-center items-center h-screen">
        <div className="w-full max-w-md  p-6">
          <h2 className="text-6xl font-mulish font-bold mb-9 text-center text-[#005D66]">
            Report a Bug
          </h2>
          <form onSubmit={handleSend} encType="multipart/form-data">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-xl font-inter font-regular mb-2 text-[#0fa3b1]"
              >
                Your e-mail address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                placeholder="Enter email address"
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-xl  font-inter font-regular mb-2 text-[#0fa3b1]"
              >
                Bug description
              </label>
              <textarea
                name="description"
                id="description"
                value={formData.description}
                onChange={handleInputChange}
                required
                rows="4"
                placeholder="Enter bug description"
                className="appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                htmlFor="file"
                className="block text-xl  font-inter font-regular mb-2 text-[#0fa3b1]"
              >
                Attachment (optional)
              </label>
              <input
                type="file"
                name="file"
                id="file"
                onChange={handleFileChange}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-[#0fa3b1] hover:bg-[#0b8c9e] text-white font-anek font-bold py-2 px-16 rounded-lg focus:outline-none focus:shadow-outline mb-3 mt-4 "
              >
                Send Report
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ReportBugPage;
