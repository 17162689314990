import Navbar from 'components/landingPage/Navbar';
import Footer from 'components/landingPage/Footer';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';

function EmailVerificationPage() {
const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const [responseMsg, setResponseMsg] = useState("");
  const notificationModalRef = useRef();

  const verifyEmail = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/verify/${token}`);
      response.data.msg += `\nYou will be redirected shortly`;
      setResponseMsg(response.data.msg);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (error) {
      console.error(error);
      error.response.data.err += `\nYou will be redirected shortly`;
      setTimeout(() => {
        navigate('/register');
      }, 5000);
      setResponseMsg(error.response.data.err);
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
    else {
      setResponseMsg("you shouldn't be here")
      setTimeout(() => {
          navigate('/login');
        }, 3000);
    }
  }, [token]);

  return (
    <>
      <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-1 flex items-center justify-center">
        {/* <NotificationModal
        ref={notificationModalRef}
        text={notification?.text}
        durationInSeconds={notification?.durationInSeconds}
        /> */}
        <div>{responseMsg}</div>
      </div>
      <Footer />
      </div>
    </>
  );
}

export default EmailVerificationPage;
