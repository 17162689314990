import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import crossIcon from "assets/icon-cross.svg";
import { editTaskAction, addTaskAction } from "../redux/boardsActions";

function AddEditTaskModal({
  type,
  device,
  setIsTaskModalOpen,
  setIsAddTaskModalOpen,
  taskIndex,
  prevColIndex = 0,
  colIndex,
  newTaskColIndex,
  setNewTaskColIndex,
}) {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const board = useSelector((state) => state.boards).find(
    (board) => board.isActive
  );

  const [isTitleEmpty, setIsTitleEmpty] = useState(false);
  const [isSubtaskEmpty, setIsSubtaskEmpty] = useState(false);

  const columns = board.columns;
  const col = columns.find((col, index) => index === prevColIndex);
  const task = col ? col.tasks.find((task, index) => index === taskIndex) : [];
  const [status, setStatus] = useState(columns[prevColIndex].name);
  const [newColIndex, setNewColIndex] = useState(prevColIndex);
  const [subtasks, setSubtasks] = useState([
    { title: "", isCompleted: false, id: uuidv4() },
  ]);

  useEffect(() => {
    if (newTaskColIndex) {
      setNewColIndex(newTaskColIndex);
    }
  }, []);

  const onChangeSubtasks = (id, newValue) => {
    setSubtasks((prevState) => {
      const newState = [...prevState];
      const subtask = newState.find((subtask) => subtask.id === id);
      subtask.title = newValue;
      return newState;
    });
  };

  const onChangeStatus = (e) => {
    setStatus(e.target.value);
    setNewColIndex(e.target.selectedIndex);
  };

  const validate = () => {
    let valid = true;

    if (!title.trim()) {
      setIsTitleEmpty(true);
      valid = false;
    } else {
      setIsTitleEmpty(false);
    }

    if (subtasks.some((subtask) => !subtask.title.trim())) {
      setIsSubtaskEmpty(true);
      valid = false;
    } else {
      setIsSubtaskEmpty(false);
    }

    return valid;
  };

  if (type === "edit" && isFirstLoad) {
    setSubtasks(
      task.subtasks.map((subtask) => {
        return { ...subtask, id: uuidv4() };
      })
    );
    setTitle(task.title);
    setDescription(task.description);
    setIsFirstLoad(false);
  }

  const onDelete = (id) => {
    setSubtasks((prevState) => prevState.filter((el) => el.id !== id));
  };

  const onSubmit = (type) => {
    if (type === "add") {
      dispatch(
        addTaskAction({
          title,
          description,
          subtasks,
          status,
          newColIndex,
        })
      );
      setNewTaskColIndex("");
    } else {
      dispatch(
        editTaskAction({
          title,
          description,
          subtasks,
          status,
          taskIndex,
          prevColIndex,
          newColIndex,
        })
      );
    }
  };

  return (
    <div
      className={
        device === "mobile"
          ? "backdrop py-1 px-6 pb-20 fixed overflow-y-auto left-0 right-0 bottom-0 top-0 dropdown z-9999"
          : "backdrop py-1 px-6 pb-20 fixed overflow-y-auto left-0 right-0 bottom-0 top-0 dropdown z-9999"
      }
    >
      {/* Modal Section */}

      <div className="overflow-y-auto max-h-[95vh] mt-5 my-auto bg-white text-black font-bold  shadow-2xl shadow-[#364e7e1a] max-w-md mx-auto w-full px-8 py-8 rounded-xl">
        <div className="flex justify-between items-center">
          <h3 className=" text-xl font-anek font-semibold text-[#0FA3B1]">
            {type === "edit" ? "Edit" : "Add New"} Task
          </h3>
          <img
            src={crossIcon}
            alt="cross icon"
            onClick={() => {
              setIsAddTaskModalOpen(false);
            }}
            className=" m-4  w-4 h-4 cursor-pointer "
          />
        </div>
        {/* Task Name */}
        <div className="mt-3 flex flex-col space-y-1 font-inter font-semibold">
          <label className="text-xl text-[#0FA3B1]">
            Task Name{" "}
            {isTitleEmpty && (
              <span className="text-red-500 text-xs mb-1">
                *You have to write something here*
              </span>
            )}
          </label>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="task-name-input"
            type="text"
            className=" bg-transparent  px-4 py-2 outline-none focus:border-0 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-1  ring-0  "
            placeholder="Enter task title... (max 35 characters)"
            maxLength={35}
          />
        </div>
        {/* Description */}
        <div className="mt-3 flex flex-col space-y-1 font-inter font-semibold">
          <label className="  text-xl   text-[#0FA3B1]">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="task-description-input"
            className=" bg-transparent outline-none min-h-[200px] focus:border-0 px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-[1px] "
            placeholder="Enter task description... (max 500 characters)"
            maxLength={500}
          />
        </div>
        {/*Column status */}
        {newTaskColIndex ? null : (
          <div className="mt-3 flex flex-col space-y-3">
            <label className="  text-xl   text-[#0FA3B1]">Column</label>
            <select
              value={status}
              onChange={onChangeStatus}
              className=" select-status flex-grow px-4 py-2 rounded-md text-sm bg-transparent focus:border-0  border-[1px] border-gray-300 focus:outline-[#0FA3B1] outline-none"
            >
              {columns.map((column, index) => (
                <option key={index}>{column.name}</option>
              ))}
            </select>
          </div>
        )}

        {/* Subtasks */}
        <div className="mt-3 flex flex-col space-y-3 font-inter font-semibold">
          <label className="  text-xl   text-[#0FA3B1]">
            Subtasks{" "}
            {isSubtaskEmpty && (
              <span className="text-red-500 ml-2 mb-2 text-xs ">
                *Enter your subtask or click X to delete*
              </span>
            )}
          </label>

          {subtasks.map((subtask, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                onChange={(e) => {
                  onChangeSubtasks(subtask.id, e.target.value);
                }}
                type="text"
                value={subtask.title}
                className=" bg-transparent outline-none focus:border-0 flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-[1px]  "
                placeholder="Write subtask name... (max 35 characters)"
                maxLength={35}
              />

              <img
                src={crossIcon}
                alt="cross icon"
                onClick={() => {
                  onDelete(subtask.id);
                }}
                className=" m-4 cursor-pointer "
              />
            </div>
          ))}

          <button
            className=" w-full items-center  bg-white text-[#0FA3B1] border border-custom-blue py-2 rounded-xl font-anek font-medium"
            onClick={() => {
              setSubtasks((state) => [
                ...state,
                { title: "", isCompleted: false, id: uuidv4() },
              ]);
            }}
          >
            + New Subtask
          </button>

          {/*create task buttons*/}
          <button
            onClick={() => {
              const isValid = validate();
              if (isValid) {
                onSubmit(type);
                setIsAddTaskModalOpen(false);
                type === "edit" && setIsTaskModalOpen(false);
              }
            }}
            className=" w-full items-center text-white bg-[#0FA3B1] py-2 rounded-xl font-anek font-semibold "
          >
            {type === "edit" ? " Save changes" : "Create task"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddEditTaskModal;
