import React, { useState } from "react";
import { useSelector } from "react-redux";
import ViewTaskModal from "./modals/ViewTaskModal";

function Task({ colIndex, taskIndex }) {
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const columns = board.columns;
  const col = columns.find((col, i) => i === colIndex);
  const task = col.tasks.find((task, i) => i === taskIndex);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  let completed = 0;
  let subtasks = task.subtasks;
  subtasks.forEach((subtask) => {
    if (subtask.isCompleted) {
      completed++;
    }
  });

  const handleOnDrag = (e) => {
    e.dataTransfer.setData(
      "text",
      JSON.stringify({ taskIndex, prevColIndex: colIndex })
    );
  };

  return (
    <div>
      <div
        onClick={() => {
          setIsTaskModalOpen(true);
        }}
        draggable
        onDragStart={handleOnDrag}
        className=" w-[280px] first:my-5 rounded-lg  bg-white shadow-[#54367e1a] py-6 px-3 shadow-lg hover:text-[#0FA3B1] cursor-pointer font-inter font-semibold"
      >
        <p className=" font-inter font-bold tracking-wide break-words ">
          {task.title}
        </p>
        <p className=" font-inter font-bold text-xs tracking-tighter mt-2 text-gray-500">
          {completed} of {subtasks.length} completed subtasks
        </p>
      </div>
      {isTaskModalOpen && (
        <ViewTaskModal
          colIndex={colIndex}
          taskIndex={taskIndex}
          setIsTaskModalOpen={setIsTaskModalOpen}
        />
      )}
    </div>
  );
}

export default Task;
