import React, { useState } from "react";
import "./css/Calendar.css";
import "./css/Responsive.css";
import Navbar from "components/AppsNavbar";
import Calendar from "./Calendar";

function Calendarr() {
  const [myStyle] = useState({
    "--event-bg-color": "#0fa3b1",
    "--today-bg-color": "#0fa3b1",
  });

  return (
    <>
      <Navbar />
      <div className="app" style={myStyle}>
        <Calendar className="my-calendar-global-padding" />
      </div>
    </>
  );
}

export default Calendarr;
