import React, { useState, useEffect } from "react";
import { useAuth } from "../../Auth";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Logo from "assets/icon-logo.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function LoginPage() {
  const navigate = useNavigate();
  const { login, cookie } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (jwt) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        { email, password }
      );

      if (response?.data) {
        const data = response.data;
        login(data.token);
        await new Promise((resolve) => setTimeout(resolve, 1000)); //temporary mozilla cookie creation fix
        window.location.reload();
      }
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white px-4 py-2 p-1">
      {/* Header */}
      <header className="w-full flex justify-between items-center">
        <a href="/">
          <div className="flex items-center ">
            <img
              src={Logo}
              alt="Logo"
              className=" text-xl h-8"
              onClick={() => navigate("/")}
            />
            <span className="text-2xl font-mulish font-bold text-[#005D66]">
              AIOFS
            </span>
          </div>
        </a>
        <button
          onClick={() => navigate("/")}
          className="bg-[#0FA3B1] text-white px-4 py-2 mr-2 rounded-lg hover:bg-[#0FA3B1] focus:outline-none focus:ring-2 focus:ring-[#0FA3B1] focus:ring-opacity-50 font-anek"
        >
          Dismiss
        </button>
      </header>
      {/* Form */}
      <div className="flex-1 flex items-start justify-center p-7 mt-24 ">
        <div className="p-8">
          <h2 className="text-6xl font-mulish font-bold text-[#005D66] mb-16 text-center">
            Log In
          </h2>
          <form onSubmit={handleLogin}>
            <div className="mb-3 w-96">
              <label className="block text-sm font-medium text-[#005D66]"></label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Enter email address..."
              />
            </div>
            <div className="mb-10">
              <label className="block text-sm font-medium text-[#005D66] mb-1"></label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Enter password..."
              />
            </div>
            <button
              type="submit"
              className="w-full px-4 py-2 bg-[#0FA3B1] text-white text-xl rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 font-anek font-medium"
            >
              Log in
            </button>
          </form>
          <p className="mt-4  text-sm text-[#005D66]">
            Don't have an account?{" "}
            <Link to="/register" className="text-[#005D66] underline">
              Sign up
            </Link>
          </p>
          <p className="mt-4  text-sm text-[#005D66]">
            Forgot password?{" "}
            <Link to="/contact" className="text-[#005D66] underline">
              Contact Us
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
