import React, { useState, useEffect,useRef } from "react";
import Note from "./Notes";
import Logo from "assets/icon-notes.svg";
import notesBlueIcon from "assets/icon-notes-blue.svg"
import Navbar from "components/AppsNavbar";
import "./App.css";
import Sidebar from "./Sidebar";
import Cookies from "js-cookie";
import axios from "axios";
import CategoryElipsisMenu from "./CategoryElipsisMenu";
import elipsis from "assets/icon-vertical-ellipsis.svg";
import { FiSearch } from "react-icons/fi";
import AddEditCategoryModal from "./AddEditCategoryModal";
import crossIcon from "assets/icon-cross.svg";
import ShareNotesModal from "./ShareNotesModal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify"
import NotePreviewModal from "./NotePreviewModal";
const App = () => {
  // Tworzenie stanów komponentu
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [boardType, setBoardType] = useState("add");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isNoteTitleEmpty,setIsNoteTitleEmpty] = useState(false);
  const [isNoteDescriptionEmpty, setIsNoteDescriptionEmpty] = useState(false);

  const elipsisMenuRef = useRef();
  useOutsideClick(elipsisMenuRef, () => setIsElipsisMenuOpen(false));

  const handleAddNoteClick = () => {
    setShowModal(true); // This will open the modal
    setCurrentNote({
      name: "",
      description: "",
      id: "",
      file: null,
      tag: "",
      category: null,
    }); // This will initialize currentNote for a new note
    setActiveTag("Custom");  // Set active tag to "Custom" when adding a new note
    setTagTitle("Custom Tag");
  };
  const [isNotePreviewModalOpen, setIsNotePreviewModalOpen] = useState(false);
  const [selectedNoteForPreview, setSelectedNoteForPreview] = useState(null);
  
  const handleNoteClick = (note) => {
    setSelectedNoteForPreview(note);
    setIsNotePreviewModalOpen(true);
  };

  const [tagTitle, setTagTitle] = useState("Tag");
  
//state for active tag button
const [activeTag, setActiveTag] = useState("");

// Nowy stan dla wyszukiwania tagów
const [searchTag, setSearchTag] = useState("");
const [filteredNotes, setFilteredNotes] = useState([]);
const [isSearchVisible, setIsSearchVisible] = useState(false); // Nowy stan dla widoczności pola wyszukiwania

const [currentNote, setCurrentNote] = useState({
  name: "",
  description: "",
  id: "",
  file: null,
  tag: "",
});


const updateIsActive = (categories, setCategories, category) => {
  const updatedCategories = categories.map((c) => ({
    ...c,
    isActive: c._id === (category ? category._id : null),
  }));

  setCategories(updatedCategories);
};

const handleButtonClick = () => {
  if (currentCategory) {
    setIsCategoryModalOpen(true);
    setIsEditing(true);
  } else {
    console.log("Nie wybrano kategorii do edycji.");
  }
};

  const setOpenEditModal = () => {
    setIsBoardModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  const setOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  
  
  const selectCategory = (category) => {
    updateIsActive(categories, setCategories, category);
    setCurrentCategory(category);
  };
  const [currentCategory, setCurrentCategory] = useState(null);

  const fetchCategories = async () => {
    try {
      const jwt = Cookies.get("jwt");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notes/category`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setCategories(response.data);
      
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const createCategory = async (name) => {
    try {
      const jwt = Cookies.get("jwt");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/notes/category`,
        { name },
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCategories([...categories, { ...response.data }]);
    } catch (error) {
      console.log(error);
    }
  };

  const editCategory = async (name) => {
    if (currentCategory) {
      try {
        const jwt = Cookies.get("jwt");
        let data = currentCategory;
        data.name=name;
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/notes/category/`,
          data,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        console.log(response)
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category._id === currentCategory._id ? { ...category, name, } : category
          )
        );
      } catch (error) {
        console.error(error);
       
      }
    }
  };

  const deleteCategory = async () => {
    if (currentCategory) {
      try {
        const jwt = Cookies.get("jwt");
        const data = {
          _id: currentCategory._id,
        }
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/notes/category`,
          {
            data: data,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        setCategories((prevCategories) =>
          prevCategories.filter((category) => category._id !== currentCategory._id)
        );
        setCurrentCategory(null);
      } catch (error) {
        console.error(error);
        const err = error.response.data.err;
        if (Array.isArray(err)) {
          return error.response.data.err.forEach((e) => {
            toast.error(e.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
        } else {
          toast.error(error.response.data.err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  };


  const fetchNotes = async () => {
    try {
      const jwt = Cookies.get("jwt");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notes/note`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setNotes(response.data);
        // Check if there are no notes and close the sidebar if true
        //if (response.data.length === 0) {
         // setIsSideBarOpen(false);
        //}
    } catch (error) {
      console.error("Error fetching notes:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  useEffect(() => {
    fetchNotes();
  }, []);

  /* Funkcja do dodawania lub edytowania notatek
  dodawanie nowych notatek lub aktualizowanie istniejących. Jeśli currentNote.id istniej oznacza to,
   że użytkownik edytuje istniejącą notatkę, więc funkcja aktualizuje odpowiednią notatkę na liście.
   Jeśli currentNote.id nie istnieje, oznacza to, że użytkownik tworzy nową notatkę, więc funkcja dodaje nową notatkę do listy.
  Po wykonaniu tych działań, funkcja zamyka modal i resetuje currentNote.*/

  const AddOrEditNote = async () => {
    let isValid = true;
    if (!currentNote.name.trim()) {
      setIsNoteTitleEmpty(true);
  
      isValid = false;
    } else {
      setIsNoteTitleEmpty(false);
    }

    if (!currentNote.description.trim()) {
      setIsNoteDescriptionEmpty(true);
     
      isValid = false;
    } else {
      setIsNoteDescriptionEmpty(false);
    }


    if (!isValid) return; // Stop the function if any validation fails

  
    if (currentNote.id) {
      try {
        let newNote = currentNote;

        const jwt = Cookies.get("jwt");
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/notes/note`,
          newNote,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        setNotes(
          notes.map((note) =>
            note.id === currentNote.id ? response.data : note
          )
        );
      } catch (error) {
        console.log(error);
        const err = error.response.data.err;
        if (Array.isArray(err)) {
          return error.response.data.err.forEach((e) => {
            toast.error(e.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
        } else {
          toast.error(error.response.data.err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    } else {
      try {
        currentNote.id = Date.now().toString();
        let newNote = currentNote;

        const jwt = Cookies.get("jwt");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/notes/note`,
          newNote,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        setNotes([...notes, { ...response.data }]);
      } catch (error) {
        console.log(error);
        const err = error.response.data.err;
        if (Array.isArray(err)) {
          return error.response.data.err.forEach((e) => {
            toast.error(e.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
        } else {
          toast.error(error.response.data.err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }

      //setNotes([...notes, { ...currentNote, id: Date.now().toString() }]);
    }
    setShowModal(false);
    setCurrentNote({ name: "", description: "", id: "", file: null });
  };

  const handleDefaultTagClick = (tagValue) => {
    setCurrentNote({ ...currentNote, tag: tagValue });
    setActiveTag(tagValue);
    setTagTitle("Tag");
  };
  
  const clearCustomTagInput = () => {
    setCurrentNote({ ...currentNote, tag: "" });
    setTagTitle("Custom Tag");
    setActiveTag("Custom");
  };

  const handleCustomTagChange = (e) => {
    setCurrentNote({ ...currentNote, tag: e.target.value });
  };

  // Obsługa zmian pliku załącznika
  //Funkcja jest wywoływana, gdy użytkownik wybiera plik za pomocą elementu input. Aktualizuje pole file w currentNote, przechowując wybrany przez użytkownika plik.
  const handleFileChange = (e) => {
    setCurrentNote({ ...currentNote, file: e.target.files[0] });
    console.log(e.target.files)
  };

  // Edycja bieżącej notatki
  //Funkcja przyjmuje notatkę jako argument i ustawia currentNote na tę notatkę, otwierając modal, aby umożliwić użytkownikowi edycję.
  const editNote = (note) => {
    setShowModal(true);
    setCurrentNote(note);
     // Define your default tags
     const defaultTags = ["School", "Work", "Private"];

     // Check if the note's tag is a default tag or a custom tag
     if (note.tag && defaultTags.includes(note.tag)) {
         setActiveTag(note.tag);
         setTagTitle("Tag");
     } else {
         setActiveTag("Custom");
         setTagTitle("Custom Tag");
     }
  };

  // Usuwanie notatki o danym ID
  //Funkcja jest używana do usuwania notatki. Przyjmuje id notatki, którą należy usunąć, a następnie ustawia stan notes na nową tablicę, która nie zawiera notatki o podanym id.
  const deleteNote = async (id) => {
    try {
      const obj = notes.find((obj) => obj.id === id); //znalezienie wybranej notatki

      const jwt = Cookies.get("jwt");
      const data = {
        _id: obj._id,
      }
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/notes/note`,
        {
          data: data,
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setNotes(notes.filter((note) => note.id !== id));
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const handleModalClick = (e) => {
    if (e.target.classList.contains("modal")) {
      setShowModal(false);
      setCurrentNote({ name: "", description: "", id: "", file: null, tag: "", category: null });
    }
  };

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);

  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setIsSideBarOpen(false);
      } else {
        setIsSideBarOpen(true);
      }
    };
  
    // Call the function on component mount
    handleResize();
  
    // Set up the event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [marginTopValue, setMarginTopValue] = useState("mt-28");
  const [marginLeftValue, setMarginLeftValue] = useState("50px");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMarginLeftValue("150px");
        setMarginTopValue("mt-56"); // Tailwind class for 36px margin-top
      } else {
        setMarginLeftValue("300px");
        setMarginTopValue("mt-28"); // Original margin-top
      }
    };
  
    // Call the function on component mount
    handleResize();
  
    // Set up the event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const AddOrEditCategory  = async () => {
    if(currentCategory.id){
    try {
      currentCategory.id = Date.now().toString();
      let newCategory = currentCategory;

      const jwt = Cookies.get("jwt");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/categories/category`,
        newCategory,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCategories([...categories, { ...response.data }]);
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  }
  setCurrentCategory({ name: "",  id: "" });

  }
  const handleCancelClick = () => {
    setShowModal(false);
    setIsCategoryModalOpen(false);
    setIsNotePreviewModalOpen(false)
    setCurrentNote({
      name: "",
      description: "",
      id: "",
      file: null,
      tag: "",
      category: null,
    });
  };


  

  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

 

  // Funkcja do aktualizacji wyszukiwanego tagu
  const handleSearchTagChange = (e) => {
    setSearchTag(e.target.value);
  };

  // Funkcja do filtrowania wydarzeń na podstawie tagu
  useEffect(() => {
    if (searchTag) {
      const lowerCaseSearchTag = searchTag.toLowerCase();
      setFilteredNotes(
        notes.filter(
          (note) => note.tag?.toLowerCase() === lowerCaseSearchTag
        )
      );
    } else {
      setFilteredNotes(notes);
    }
  }, [searchTag, notes]);

  // UI dla wyszukiwania tagów
const renderTagSearch = () =>
isSearchVisible && (
  <div className="search-box">
    <input
      type="text"
      placeholder="Search by tag..."
      value={searchTag}
      onChange={handleSearchTagChange}
      className="search-input font-anek"
    />
    <button className= "font-anek " onClick={() => setSearchTag("")}>Clear</button>
  </div>
);

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

  // Renderowanie UI aplikacji
  return (
    
    <div className={`sticky text-[#0fa3b1] text-center ${showModal ? "modal-open" : ""}`}>
      <div className="headerContainer">
        <div style={{position:"static"}}>
        <Navbar />
        </div>
      
        <header style={{position:"fixed",width:"100%",height:"56px"}} className="   flex bg-white  items-center shadow-[#54367e1a] shadow-lg">
          <img src={notesBlueIcon} alt="logo-notes" className="h-7 w-7 ml-4"></img>
          <h3 className="md:text-4xl font-anek ml-4 font-medium text-[#0FA3B1]">
            Notes
          </h3>




          <div className="search-trigger" onClick={toggleSearch}>
          <FiSearch className="search-icon" />
    
        </div>
        {/* Wywołanie renderTagSearch tutaj, aby pokazać pole wyszukiwania pod ikoną lupy */}
        {renderTagSearch()}
          
        {currentCategory && (
        <div >
        <h2 className="font-anek font-semibold" style={{ display: 'block', marginLeft: "100px", fontSize: "24px" }}> {currentCategory.name}</h2>
        </div>
        )}
       
       
        <button  style={{backgroundColor:"#0fa3b1", color:"white",paddingLeft:16,paddingRight:16,paddingTop:8,paddingBottom:8,marginLeft:"auto",marginRight:currentCategory !=null ? "0px" : "74px"}} className="rounded-xl font-anek font-medium" onClick={() => setShowModal(true)}>
          +New Note
        </button>
        {currentCategory != null && (
        <img
            onClick={() => {
              setBoardType("edit");
              setIsElipsisMenuOpen((prevState) => !prevState);
            }}
            src={elipsis}
            alt="elipsis"
            className=" cursor-pointer h-6  "
            style={{marginRight:"30px",marginTop:"5px",marginLeft:"20px"}}
          />
        )}
          {isElipsisMenuOpen && (
            <div ref={elipsisMenuRef}>
          <CategoryElipsisMenu
          type="Category"
          setIsCategoryModalOpen={setIsCategoryModalOpen}
          setOpenDeleteModal={setOpenDeleteModal}
          onEdit={() => handleButtonClick()} 
          onDelete={deleteCategory}
          setIsShareModalOpen={setIsShareModalOpen}
          currentCategory={currentCategory}
         
          
          />
          </div>
          )}
        
              </header>  
              
      </div>
      <div >
        <div className="sidebar" >
        <Sidebar
          createCategory={createCategory}
          editCategory={editCategory}
          deleteCategory={deleteCategory}
          setIsBoardModalOpen={setIsBoardModalOpen}
          isBoardModalOpen={isBoardModalOpen}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
          categories={categories}
          setCategories={setCategories}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isCategoryModalOpen={isCategoryModalOpen}
          setIsCategoryModalOpen={setIsCategoryModalOpen}
          selectCategory={selectCategory}
          updateIsActive={updateIsActive}
        />
        </div>



        {isCategoryModalOpen && (
        <AddEditCategoryModal
          type={isEditing ? "edit" : "add"}
          setIsCategoryModalOpen={setIsCategoryModalOpen}
          createCategory={createCategory}
          editCategory={editCategory}
          deleteCategory={deleteCategory}
          currentCategory={currentCategory}
          handleCancelClick={handleCancelClick}
        />
      )}
       
       {isShareModalOpen && (
        <ShareNotesModal 
        setIsShareModalOpen={setIsShareModalOpen} 
        setCurrentCategory={setCurrentCategory}
        onClose={() => setIsShareModalOpen(false)}
        currentCategory={currentCategory}
        categories={categories}
        setCategories={setCategories}
        />
      )}



     
       

        <div className="bg-[#f4f7fd]"style={{ marginLeft: isSideBarOpen ? '270px' : '0' }}>
          {showModal && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50" onClick={handleModalClick}>
              
              <div className="modal-inner overflow-y-auto">
                <div style={{fontWeight:'bold',textAlign: 'left',height:"650px"}}>
                
                <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 className="text-xl font-anek"
              style={{display: 'inline-block',marginRight:'auto'}}>{showModal ? (currentNote.id ? "Edit Note" : "Add New Note") : ""}</h2>
              
               <img
                src={crossIcon}
                alt="cross icon"
                onClick={handleCancelClick}
                className=" m-4  w-4 h-4 cursor-pointer "
                style={{marginRight:'0'}}
              />
            </div>

              <div>
              <div className=" relative flex   justify-between w-full items-center"></div>
              <div style={{textAlign: 'left'}}>
                <label className=" text-xl font-inter">Note Title{isNoteTitleEmpty && (
              <span className="text-red-500 text-xs mb-1">
                {' '}*You have to write something here*
              </span>
            )}</label> 

              </div>
              
                
                <input
                 className=" bg-transparent  px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-1  ring-0  "
                 style={{ width: '100%',color:'black' }}
                  value={currentNote.name}
                  onChange={(e) =>
                    setCurrentNote({ ...currentNote, name: e.target.value })
                  }
                  placeholder="Enter note title...(max 35 characters)"
                  max={35}
                />
                </div>
                <div>
                <label className="text-xl font-inter  " style={{textAlign: 'left'}}>Description
                {isNoteDescriptionEmpty && (
              <span className="text-red-500 text-xs mb-1">
                {' '}*You have to write something here*
              </span>)}
              </label>
              
                <textarea
                 className=" bg-transparent  px-4 py-2 rounded-md text-sm font-inter font-semibold border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-1  ring-0  "
                 style={{ height: '200px',width: '100%',color:"black" }}
                  value={currentNote.description}
                  onChange={(e) =>
                    setCurrentNote({
                      ...currentNote,
                      description: e.target.value,
                    })
                  }
                  placeholder="Enter note description...(max 500 characters)"
                  maxLength={500}
                />
               </div>
              



              <div >
                <label className="text-xl font-inter" style={{ textAlign: 'left' }}>{tagTitle}</label>
                <div className="custom-tag-buttons-container w-50 ">
                  <Button variant={`outline-secondary custom-tag-button ${activeTag === "School" ? "clicked" : ""}`} onClick={() => handleDefaultTagClick("School")}>School</Button>
                  <Button variant={`outline-secondary custom-tag-button ${activeTag === "Work" ? "clicked" : ""}`} onClick={() => handleDefaultTagClick("Work")}>Work</Button>
                  <Button variant={`outline-secondary custom-tag-button ${activeTag === "Private" ? "clicked" : ""}`} onClick={() => handleDefaultTagClick("Private")}>Private</Button>
                  <Button variant={`outline-secondary custom-tag-button ${activeTag === "Custom" ? "clicked" : ""}`}onClick={() => {clearCustomTagInput();setActiveTag("Custom");}}>Custom</Button>
                </div>
                <input
                  className={`bg-transparent px-4 py-2 rounded-md text-sm  border-2 border-gray-500 focus:outline-[#0FA3B1] ring-0 w-full ${
                    activeTag !== "Custom" ? 'bg-[#F4F6F6] text-gray-300 cursor-not-allowed  '  : '  bg-white text-black'
                  }`}
                  value={currentNote.tag}
                  onChange={handleCustomTagChange}
                  placeholder="Write custom tag..."
                  maxLength={25}
                  disabled={activeTag !== "Custom"}
                />
              </div>
               <div>
                <label style={{ marginBottom: '20px', textAlign: 'left' }}className="text-xl font-inter border-xl">Category</label>
                <select class="select-status font-inter rounded-md focus:outline-[#0FA3B1] border-gray-500 border-[1px] px-4"
                 style={{ width: '100%', height:"40px", marginBottom: '40px',color:"black",fontWeight:'normal' }}
               value={currentNote.category}
                onChange={(e) =>
                
                 setCurrentNote({ ...currentNote, category: e.target.value })
                }
                >
                <option value={null}>Select Category</option>
                {categories.map((category) => (
                <option key={category._id} value={category._id}>
                {category.name}
                </option>
                ))}
                </select>
                </div>
                <button className="rounded-xl font-anek" onClick={AddOrEditNote}
                 style={{
                  backgroundColor: '#0FA3B1',
                  color: 'white',
                  padding: '10px', // Dodaj padding w razie potrzeby
                  border: 'none', // Usuń domyślne obramowanie
                  cursor: 'pointer', // Ustaw styl kursora na wskaźnik
                  width:"100%",
                  margin:'auto', // Dodaj to, aby wycentrować przycisk
                  display: 'block'  // Dodaj to, aby przycisk był blokiem i korzystał z margin: 'auto'
                }}>{showModal ? (currentNote.id ? "Save changes" : "Create note") : ""}</button>
              </div>
              
            </div>
          </div>
          )}
          {notes.length > 0 ? (
         <div className={`${marginTopValue} flex flex-wrap ${isSideBarOpen ? "justify-start" : "justify-start"}`}>
          {currentCategory === null
            ? filteredNotes.map((note) => (
                <Note
                  key={note.id}
                  note={note}
                  onEdit={editNote}
                  onDelete={deleteNote}
                  currentNote={currentNote}
                  handleNoteClick={() => handleNoteClick(note)}
                  handleCancelClick={handleCancelClick}
                  setCurrentNote={setCurrentNote}
                  setIsShareModalOpen={setIsShareModalOpen}
                  notes={notes}
                  isNotePreviewModalOpen={isNotePreviewModalOpen}
                  
                  
                />
              ))
            : filteredNotes.map((note) => {
                if (note.category === currentCategory?._id) {
                  return (
                    <Note
                      key={note.id}
                      note={note}
                      onEdit={editNote}
                      onDelete={deleteNote}
                      currentNote={currentNote}
                      setCurrentNote={setCurrentNote}
                      handleNoteClick={() => handleNoteClick(note)}
                      isElipsisMenuOpen={isElipsisMenuOpen}
                      setIsElipsisMenuOpen={setIsElipsisMenuOpen}
                      notes={notes}
                    />
                  );
                }
                return null; // Jeśli notatka nie pasuje do kategorii, zwróć null, aby jej nie wyświetlać.
              })}
          </div>
            ) : (
              <div className="bg-white h-screen w-100% flex flex-col  items-center justify-center" 
              style={{paddingTop:"40px",
             backgroundColor:"#FFFFFF"}}>
                
                <div >  
                <h3 class="text-gray-500 font-bold">No notes available. Click the button below to add a new note.</h3>
                <button class="w-full items-center justify-center max-w-xs font-bold  mt-8 relative text-white bg-[#0FA3B1] py-2 rounded-full" 
                style={{marginTop:"20px"}} onClick={handleAddNoteClick}  >
                  +New Note
                </button>
                </div>
               
              </div>
            )}
             {isNotePreviewModalOpen && (
    <NotePreviewModal
      note={selectedNoteForPreview}
      onClose={() => setIsNotePreviewModalOpen(false)}
      setIsNotePreviewModalOpen={setIsNotePreviewModalOpen}
      onEdit={editNote}
      onDelete={deleteNote}
      currentNote={currentNote}
      setCurrentNote={setCurrentNote}
      isElipsisMenuOpen={isElipsisMenuOpen}
      setIsElipsisMenuOpen={setIsElipsisMenuOpen}
      notes={notes}
    />
  )}
        </div>
      </div>
    </div>
  
  );
};

export default App;