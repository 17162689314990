import React, { useEffect } from "react";
import { useAuth } from "Auth";
import Navbar from "components/AppsNavbar";
import Logo from "assets/icon-logo.svg";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import { TypeAnimation } from "react-type-animation";
function Dashboard() {
  const { user } = useAuth();

  useEffect(() => {
    if (user != null && !user?.isVerified) {
      toast(
        "Please ensure that you verify your account within one hour of registration by clicking the link provided in the email. Failure to do so will result in the deletion of your account. ",
        {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setTimeout(() => {}, 2000);
    }
  }, [user]);
  if (!user) {
    return <Loading />;
  }
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Navbar />
      <div className="flex-grow text-center flex flex-col items-center px-4 py-10 mt-10 lg:mt-44">
        <div className="mb-4">
          <h1 className="text-5xl text-[#0FA3B1] font-light mb-12 ">
            Hello, {user.nickname}
          </h1>

          <div className="flex items-center justify-center">
            <img
              src={Logo}
              alt="Logo AIOFS"
              className="h-13 w-auto mr-2 self-start"
            />
            <div className="text-6xl text-[#005D66] font-mulish font-bold ml-2 mb-12">
              <TypeAnimation
                sequence={["All-In-One For Students", 2000]}
                wrapper="div"
                cursor={true}
                repeat={1}
                speed={10}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-4 lg:mt-10 lg: ">
          <a href="/calendar">
            <button className="w-full md:w-64 lg:w-80 bg-[#0fa3b1] text-white font-anek font-medium text-base md:text-xl lg:text-2xl rounded-lg px-6 py-3">
              Calendar
            </button>
          </a>
          <a href="/todo">
            <button className="w-full md:w-64 lg:w-80 bg-[#0fa3b1] text-white font-anek font-medium text-base md:text-xl lg:text-2xl rounded-lg px-6 py-3">
              To do
            </button>
          </a>
          <a href="/notes">
            <button className="w-full md:w-64 lg:w-80 bg-[#0fa3b1] text-white font-anek font-medium text-base md:text-xl lg:text-2xl rounded-lg px-6 py-3">
              Notes
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
