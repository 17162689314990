import React from "react";
import Navbar from "components/landingPage/Navbar"; // import navbar

function TermsOfUse() {
  return (
    <div className="bg-white font-inter font-semibold ">
      <Navbar />
      <div className="container mx-auto px-6 py-8">
        <h1 className="text-5xl font-mulish font-bold text-center mb-4 text-[#005D66]">
          Terms of Use
        </h1>
        <section className="space-y-6">
          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              1. Introduction
            </h2>
            <p className="ml-6">
              Welcome to AIOFS! These Terms of Use govern your use of our
              website and services. By registering as a user, you agree to
              comply with these terms.
            </p>
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              2. User Registration
            </h2>
            <p className="ml-6">
              <span className="font-semibold">a.</span> To access features of
              our site, you must register.
            </p>
            <p className="ml-6">
              <span className="font-semibold">b.</span> During registration, you
              must provide true, current, and complete information about
              yourself.
            </p>
            <p className="ml-6">
              <span className="font-semibold">c.</span> You are responsible for
              maintaining the confidentiality of your password and account.
            </p>
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              3. Use of the Application
            </h2>
            <p className="ml-6">
              a. Our site is available only to individuals who are at least 13
              years old.
              <br />
              b. You may not use our site for any unlawful or harmful purposes.
              <br />
              c. We reserve the right to modify or discontinue any part of the
              site at any time.
            </p>
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              4. Limitation of Liability
            </h2>
            <p className="ml-6">
              We are not liable for any damages arising from the use or
              inability to use the site.
            </p>
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              5. Changes to Terms of Use
            </h2>
            <p className="ml-6">
              We may change these terms at any time. Continuing the use of the
              site after changes indicates acceptance of the new terms.
            </p>
          </div>

          <div className="space-y-2">
            <h2 className="text-2xl font-semibold text-[#0FA3B1]">
              6. Contact
            </h2>
            <p className="ml-6">
              If you have any questions about these terms, please contact us at:{" "}
              <a href="mailto:admin@aiofs.space">admin@aiofs.space</a>
            </p>
          </div>
          <div className="pb-10"></div>
        </section>
      </div>
    </div>
  );
}

export default TermsOfUse;
