import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import arrowUp from "assets/arrow-chevron-up.svg";
import ShareBoardModal from "./modals/ShareBoardModal";
import elipsis from "assets/icon-vertical-ellipsis.svg";
import boardIcon from "assets/icon-board-star-grey.svg";
import crossIcon from "assets/icon-cross.svg";
import boardIconBlue from "assets/icon-board-star-blue.svg";
import ReactDOM from "react-dom";
import boardIconWhite from "assets/icon-star-white.svg";
import linkIconWhite from "assets/icon-link-white.svg";
import arrowDown from "assets/icon-chevron-down.svg";
import ConfirmationModal from "./modals/ConfirmationDeleteModal";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import linkIconBlue from "assets/icon-link-blue.svg";

const ManagementCalendars = ({
  activeCalendar,
  setActiveCalendar,
  setCalendarSpecificEvents,
  events,
}) => {
  const [isCalendarListOpen, setIsCalendarListOpen] = useState(false);
  const [calendarList, setCalendarList] = useState([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [newCalendarName, setNewCalendarName] = useState("");
  const [isNewCalendarFormOpen, setIsNewCalendarFormOpen] = useState(false);
  const [isEditCalendarMode, setIsEditCalendarMode] = useState(false);
  //delete modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const jwt = Cookies.get("jwt");
  // otworzenie listy kalendarzy
  const handleViewCalendarList = () => {
    setIsCalendarListOpen(!isCalendarListOpen);
  };

  // elipis menu open/close
  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  // share modal
  const openShareModal = () => {
    if (activeCalendar === undefined) {
      return alert('please select calendar other than "My private"');
    }
    setIsShareModalOpen(true);
  };

  //mini modal dodawania nowego kalendarza po kliknięciu + new calendar
  const handleOpenNewCalendarForm = () => {
    setIsNewCalendarFormOpen(!isNewCalendarFormOpen);
    setIsEditCalendarMode(false);
  };
  const handleCreateCalendar = () => {
    createNewCalendar();
  };

  // otwarcie edycji aktywnego kalendarza
  const handleOpenEditCalendarForm = () => {
    if (activeCalendar === undefined) {
      return alert('please select calendar other than "My private"');
    }
    setIsNewCalendarFormOpen(!isNewCalendarFormOpen);
    setIsEditCalendarMode(true);
  };
  const handleEditCalendar = () => {
    editCalendar();
  };

  const getCalendarList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/calendar/calendar`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCalendarList(response.data);
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  useEffect(() => {
    getCalendarList();
  }, []);

  const createNewCalendar = async () => {
    try {
      let data = {
        name: newCalendarName,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/calendar/calendar`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCalendarList([...calendarList, response.data]);
      setActiveCalendar(response.data);
      setIsNewCalendarFormOpen(false);
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  const editCalendar = async () => {
    try {
      let data = {
        _id: activeCalendar._id,
        name: newCalendarName,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/calendar/calendar`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCalendarList(
        calendarList.map((calendar) =>
          calendar._id === activeCalendar._id ? response.data : calendar
        )
      );
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  const deleteCalendar = async () => {
    if (activeCalendar === undefined) {
      return alert('please select calendar other than "My private"');
    }
    try {
      let data = {
        _id: activeCalendar._id,
      };
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/calendar/calendar`,
        {
          data,
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      setCalendarList(
        calendarList.filter((calendar) => calendar._id !== activeCalendar._id)
      );
      setActiveCalendar(undefined);
    } catch (error) {
      console.log(error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  useEffect(() => {
    setCalendarSpecificEvents(
      events.filter((event) => event?.calendar === activeCalendar?._id)
    );
  }, [activeCalendar, events]);

  //delete calendar modal
  const confirmDelete = () => {
    deleteCalendar();
    setShowConfirmationModal(false);
  };

  // zamykanie elipsisa na kliknięciu gdzieś indziej
  const menuButtonRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      isMenuOpen &&
      !event.target.closest(".calendar-list-menu-dropdown") &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <h3 onClick={handleViewCalendarList}>Calendar</h3>
      <img
        onClick={handleViewCalendarList}
        src={isCalendarListOpen ? arrowUp : arrowDown}
        alt={isCalendarListOpen ? "arrow-up" : "arrow-down"}
        className="arrow h-4 w-4"
      />

      {isCalendarListOpen && (
        <div className="calendar-list-container">
          <div className="calendar-list-header">
            <div className="calendar-list-title ">
              All calendars ({calendarList.length})
            </div>
            <div className="calendar-list-menu">
              {/* Conditional rendering of ellipsis dropdown */}
              {activeCalendar !== undefined && (
                <>
                  <img
                    src={elipsis}
                    alt="menu"
                    className="calendar-list-menu-icon w-7 h-7"
                    onClick={toggleMenu}
                    ref={menuButtonRef}
                  />
                  {isMenuOpen && (
                    <div className="calendar-list-menu-dropdown">
                      <button
                        onClick={() => {
                          handleOpenEditCalendarForm();
                        }}
                        className="calendar-list-menu-button"
                      >
                        Edit calendar{" "}
                      </button>
                      <button
                        onClick={() => {
                          openShareModal();
                        }}
                        className="calendar-list-menu-button"
                      >
                        Share calendar
                      </button>
                      <button
                        onClick={() => setShowConfirmationModal(true)}
                        className="calendar-list-menu-button-delete"
                      >
                        Delete calendar
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <ul className="calendar-list font-inter font-medium ">
            <div
              onClick={() => setActiveCalendar(undefined)}
              className={`calendar-item ${
                activeCalendar === undefined ? "active" : ""
              }`}
            >
              <img
                src={activeCalendar === undefined ? boardIconWhite : boardIcon}
                alt="board icon"
                className="ml-2.5 board-icon"
              />
              <li>Personal</li>
            </div>

            {calendarList.map((calendar) => {
              const isActive = activeCalendar === calendar;
              return (
                <li
                  onClick={() => {
                    setActiveCalendar(calendar);
                    console.log(activeCalendar);
                  }}
                  className={`calendar-item ${isActive ? "active" : ""}`}
                >
                  <div className="calendar-item ml-2.5 ">
                    <img
                      src={isActive ? boardIconWhite : boardIcon}
                      alt="board icon"
                      className="board-icon"
                    />
                    <span className="calendar-name">{calendar.name}</span>
                    {calendar.sharedUsers.length !== 0 ? (
                      <img
                        src={isActive ? linkIconWhite : linkIconBlue}
                        alt="share"
                        className="share-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveCalendar(calendar);
                          setIsShareModalOpen(true);
                        }}
                      />
                    ) : null}
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="calendar-list-add-new font-anek font-medium">
            <img
              src={boardIconBlue}
              alt="board icon blue"
              className="board-icon"
            />
            <button onClick={handleOpenNewCalendarForm}>+ New calendar</button>
          </div>
        </div>
      )}
      {isNewCalendarFormOpen && (
        <div className="add-calendar-modal">
          <div className="modal-header">
            {isEditCalendarMode ? (
              <h2>Edit Calendar</h2>
            ) : (
              <h2>Add New Calendar</h2>
            )}
            <img
              alt="cross icon"
              src={crossIcon}
              onClick={() => setIsNewCalendarFormOpen(false)}
              className="cross-icon"
            />
          </div>
          <div className="modal-body">
            <label htmlFor="newCalendarName">Calendar Name</label>
            <input
              type="text"
              id="newCalendarName"
              value={newCalendarName}
              onChange={(e) => setNewCalendarName(e.target.value)}
              placeholder="Enter calendar name"
              maxLength={25}
            />
          </div>
          <div className="modal-footer">
            {isEditCalendarMode ? (
              <button onClick={handleEditCalendar}>Edit Calendar</button>
            ) : (
              <button onClick={handleCreateCalendar}>Create Calendar</button>
            )}
          </div>
        </div>
      )}

      {isShareModalOpen &&
        ReactDOM.createPortal(
          <div className="share-modal">
            <ShareBoardModal
              onClose={() => setIsShareModalOpen(false)}
              setIsShareModalOpen={setIsShareModalOpen}
              activeCalendar={activeCalendar}
              setActiveCalendar={setActiveCalendar}
              calendarList={calendarList}
              setCalendarList={setCalendarList}
            />
          </div>,
          document.body
        )}

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleConfirm={confirmDelete}
      />
    </>
  );
};
export default ManagementCalendars;
