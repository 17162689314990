import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from "js-cookie";
const jwt = Cookies.get("jwt");

const ProtectedRoute = ({ children }) => {
  if(!jwt)
  return (
    <Navigate to="/login" replace />
  );
  return children
};

export default ProtectedRoute;