import React, { useState } from "react";
import crossIcon from "assets/icon-cross.svg";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addBoardAction, editBoardAction } from "../redux/boardsActions";

function AddEditBoardModal({ setIsBoardModalOpen, type }) {
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [name, setName] = useState("");
  const [newColumns, setNewColumns] = useState([
    { name: "Todo", tasks: [], id: uuidv4() },
    { name: "Doing", tasks: [], id: uuidv4() },
    { name: "Done", tasks: [], id: uuidv4() },
  ]);
  const [isValid, setIsValid] = useState(true);
  const board = useSelector((state) => state.boards).find(
    (board) => board.isActive
  );

  if (type === "edit" && isFirstLoad) {
    setNewColumns(
      board.columns.map((col) => {
        return { ...col, id: uuidv4() };
      })
    );
    setName(board.name);
    setIsFirstLoad(false);
  }

  const validate = () => {
    setIsValid(false);
    if (!name.trim()) {
      return false;
    }
    for (let i = 0; i < newColumns.length; i++) {
      if (!newColumns[i].name.trim()) {
        return false;
      }
    }
    setIsValid(true);
    return true;
  };

  const onChange = (id, newValue) => {
    setNewColumns((prevState) => {
      const newState = [...prevState];
      const column = newState.find((col) => col.id === id);
      column.name = newValue;
      return newState;
    });
  };

  const onDelete = (id) => {
    setNewColumns((prevState) => prevState.filter((el) => el.id !== id));
  };

  const onSubmit = (type) => {
    setIsBoardModalOpen(false);
    if (type === "add") {
      dispatch(addBoardAction({ name, newColumns }));
    } else {
      dispatch(editBoardAction({ name, newColumns }));
    }
  };

  return (
    <div className=" fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown ">
      <div
        className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  bg-white  text-black
       shadow-md shadow-[#364e7e1a] max-w-md mx-auto my-auto w-full px-8  py-8 rounded-xl"
      >
        <div className="flex justify-between items-center">
          <h3 className=" text-xl text-[#0FA3B1] font-anek font-semibold  ">
            {type === "edit" ? "Edit" : "Add New"} Board
          </h3>
          <img
            src={crossIcon}
            alt="cross icon"
            onClick={() => {
              setIsBoardModalOpen(false);
            }}
            className=" m-4  w-4 h-4 cursor-pointer "
          />
        </div>

        {/* Task Name */}

        <div className="mt-3 flex flex-col space-y-1">
          <label className="  text-xl text-[#0FA3B1]">Board Name</label>
          <input
            className=" bg-transparent  px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-1  ring-0  "
            placeholder=" e.g Web Design"
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="board-name-input"
            maxLength={25}
          />
        </div>

        {/* Board Columns */}

        <div className="mt-3 flex flex-col space-y-3">
          <label className=" text-xl text-[#0FA3B1]">Board Columns</label>

          {newColumns.map((column, index) => (
            <div key={index} className=" flex items-center w-full ">
              <input
                className=" bg-transparent flex-grow px-4 py-2 rounded-md text-sm  border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-[1px]   "
                onChange={(e) => {
                  onChange(column.id, e.target.value);
                }}
                type="text"
                value={column.name}
                maxLength={15}
                placeholder="Write columns name... (max 15 characters)"
              />
              <img
                src={crossIcon}
                onClick={() => {
                  onDelete(column.id);
                }}
                className=" m-4 cursor-pointer "
                alt="cross icon"
              />
            </div>
          ))}
          <div>
            <button
              className=" w-full items-center hover:opacity-70 bg-white py-2 rounded-xl text-[#0FA3B1] border border-custom-blue  font-anek font-semibold "
              onClick={() => {
                setNewColumns((state) => [
                  ...state,
                  { name: "", tasks: [], id: uuidv4() },
                ]);
              }}
            >
              + New Column
            </button>
            <button
              onClick={() => {
                const isValid = validate();
                if (isValid === true) onSubmit(type);
              }}
              className=" w-full items-center hover:opacity-70  mt-3 relative  text-white bg-[#0FA3B1] py-2 rounded-xl font-anek font-semibold"
            >
              {type === "add" ? "Create New Board" : "Save Changes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEditBoardModal;
