import React, { useState } from "react";
import { useAuth } from "Auth";
import Navbar from "components/AppsNavbar";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "components/Loading";
import Marker from "assets/icon-marker-white.svg";
import { toast } from "react-toastify";
import AvatarIcon from "assets/icon-account-white-bg.svg";

function Settings() {
  const jwt = Cookies.get("jwt");
  const {
    user,
  } = useAuth();

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentDeletePassword, setCurrentDeletePassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [retypeEmail, setretypeEmail] = useState("");
  const { logout } = useAuth();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleAvatarChange(selectedFile);
    }
  };

  const handleAvatarChange = async (selectedFile) => {
    const dataToSend = new FormData();
    dataToSend.append("file", selectedFile);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/settings/avatar`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      );
      if (response.status == 200) {
        window.location.reload();
      }
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (newPassword !== retypePassword) {
      toast.error("New password and retype password do not match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    try {
      let data = {
        password: currentPassword,
        newPassword: newPassword,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/settings/password`,
        data,
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      );
      toast.info(response.data.msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setCurrentPassword("")
      setNewPassword("")
      setRetypePassword("")
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const handleAccountDeletion = async (e) => {
    e.preventDefault();
    try {
      let data = {
        password: currentDeletePassword,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/settings/delete`,
        data,
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      );
      logout();
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Navbar />
      <div className="flex-grow flex justify-center items-center">
        <div className="rounded-lg p-8 m-4 w-full lg:w-1/2 flex">
          {/* Display user avatar */}
          <div className="relative">
            <img
              src={user.avatar ? user.avatar : AvatarIcon }
              alt="User Avatar"
              className="w-32 h-32 rounded-full mr-20"
              onClick={() => {
                document.getElementById("avatarInput").click();
              }}
              style={{ cursor: "pointer" }}
            />
            <img
              src={Marker}
              alt="marker change avatar"
              className="absolute top-24 right-20 w-7 cursor-pointer"
              onClick={() => {
                document.getElementById("avatarInput").click();
              }}
            />
          </div>
          <input
            type="file"
            id="avatarInput"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <div>
            <h1 className="text-5xl text-[#0FA3B1] font-mulish font-bold">
              {user.nickname}
            </h1>
            <div className="mt-8 space-y-3">
              <h2 className="self-start text-[#0FA3B1]">Email address:</h2>
              <h2 className="self-start text-black  font-semibold">
                {user.email}
              </h2>
            </div>

            {/* Password Change Form */}
            <div className="mt-12">
              <form onSubmit={handlePasswordChange} className="space-y-2">
                <h2 className="self-start text-[#0FA3B1]">Change password:</h2>
                <input
                  type="password"
                  placeholder="Enter your password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="border-black border rounded-lg p-2 "
                />
                <br />
                <input
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="border-black border rounded-lg p-2 "
                />
                <div className="flex  items-center space-x-2">
                  <input
                    type="password"
                    placeholder="Repeat new password"
                    value={retypePassword}
                    onChange={(e) => setRetypePassword(e.target.value)}
                    className="border-black border rounded-lg p-2 mr-3"
                  />
                  <button
                    type="submit"
                    className="bg-white text-[#0FA3B1] border  border-custom-blue rounded-xl  py-2 ml-6 px-7 font-anek font-medium"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>

            {/* Account Deletion Form */}
            <div className="mt-12">
              <form onSubmit={handleAccountDeletion} className="space-y-4">
                <h2 className="self-start text-[#0FA3B1]">Delete account:</h2>
                <div className="flex items-center space-x-2">
                  <input
                    type="password"
                    placeholder="Enter your password"
                    value={currentDeletePassword}
                    onChange={(e) => setCurrentDeletePassword(e.target.value)}
                    className="border-black border rounded-lg p-2 mr-3"
                  />
                  <button
                    type="submit"
                    className="bg-white text-[#0FA3B1] border border-custom-blue rounded-xl  py-2  ml-5 px-5 font-anek font-medium"
                  >
                    Delete Account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
