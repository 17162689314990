import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import crossIcon from "assets/icon-cross.svg";
import React, { useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationDeleteModal";

function MyModal({
  modalStatus,
  handleClose,
  handleSave,
  handleChange,
  startDate,
  endDate,
  eventInput,
  handleEditEvent,
  handleEdited,
  editStatus,
  handleDelete,
  setStartDate,
  setEndDate,
  customTag,
  handleCustomTagChange,
  errors,
  handleColorSelect,
  selectedColor,
  eventDescription,
  handleDescriptionChange,
  onEventDeleted,
}) {
  // tag menagment in modal
  const [tagTitle, setTagTitle] = useState("Tag");

  const [customTagInput, setCustomTagInput] = useState(customTag || "");

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [activeTag, setActiveTag] = useState("");

  const clearCustomTagInput = () => {
    handleCustomTagChange({ target: { value: "" } });
    handleCustomTagClick();
  };

  const handleCustomTagClick = () => {
    setTagTitle("Custom Tag");
    setCustomTagInput("");
  };

  const handleDefaultTagClick = () => {
    setTagTitle("Tag");
  };

  // reset activeTag state when open new event modal
  useEffect(() => {
    if (modalStatus && !editStatus) {
      setActiveTag("");
    }
  }, [modalStatus, editStatus]);

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  // when user click on ,,Delete" show new modal. If click ,,Yes" in confirmation modal use handleDelete and close modal, if no close modal without use handleDelete
  const confirmDelete = () => {
    handleDelete();
    setShowConfirmationModal(false);
    if (onEventDeleted) {
      onEventDeleted();
    }
  };

  return (
    <>
      <Modal show={modalStatus} className="my-modal ">
        <Modal.Header className="sub-event-title modal-header modal-top-bot-font">
          <div className="sub-event-title-h1-container">
            <h1 className="sub-event-title-h1">
              {editStatus ? "Edit Event" : "Create New Event"}
            </h1>
            <img
              alt="cross icon"
              src={crossIcon}
              onClick={handleClose}
              className="cross-icon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-scrollable">
          <Form>
            {/* for creating  new event */}
            {!editStatus && (
              <Form.Group
                className="form-margin-bottom"
                controlId="exampleForm.ControlTextarea1"
              >
                <div className=" error-event-title-container">
                  <h2 className="sub-event-title">Event title</h2>
                  {errors.eventTitle && (
                    <span className="text-danger error-event-title-text">
                      {errors.eventTitle}
                    </span>
                  )}
                </div>
                <Form.Control
                  as="textarea"
                  rows={3}
                  maxLength={50}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter event title... (max. 50 characters)"
                />
              </Form.Group>
            )}

            {/* for editing created event  */}
            {editStatus && (
              <Form.Group
                className="form-margin-bottom "
                controlId="exampleForm.ControlTextarea1"
              >
                <div className="error-event-title-container">
                  <h2 className="sub-event-title">Event title</h2>
                  {errors.eventTitle && (
                    <span className="text-danger error-event-title-text">
                      {errors.eventTitle}
                    </span>
                  )}
                </div>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={eventInput}
                  maxLength={50}
                  onChange={handleEditEvent}
                  className="form-control"
                  placeholder="Enter event title... (max 50 characters)"
                />
              </Form.Group>
            )}

            {/* Dodawanie pola Description */}
            <Form.Group
              className="form-margin-bottom"
              controlId="eventDescriptionInput"
            >
              <Form.Label>
                <h2 className="sub-event-title">Description</h2>
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter event description... (max 500 characters)"
                className="form-control-description"
                maxLength={500}
                value={eventDescription}
                onChange={handleDescriptionChange}
              />
            </Form.Group>

            <h2 className="sub-event-title">Date-time </h2>
            {/* Dodaje grupę formularza z etykietą i komponentem DatePicker do wyboru daty i czasu rozpoczęcia wydarzenia. */}
            <div className="form-row">
              <Form.Group
                className="form-group-pick-date col"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-fixed-width">
                  Start Event:{" "}
                </Form.Label>
                <DatePicker
                  className="datePicker datePickerBorder "
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="d MMM, yyyy, HH:mm"
                />
              </Form.Group>

              <Form.Group
                className="form-group-end-time col"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="label-fixed-width">
                  End Event:{" "}
                </Form.Label>
                <DatePicker
                  className="datePicker datePickerBorder"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="d MMM, yyyy, HH:mm"
                />
              </Form.Group>
            </div>

            {/* zmiana koloru */}
            <Form.Group
              className="form-margin-bottom"
              controlId="colorSelector"
            >
              <Form.Label>
                <span className="sub-event-title">Color</span>
                {errors.selectedColor && (
                  <span className="text-danger">{errors.selectedColor}</span>
                )}
              </Form.Label>
              <div className="default-colors-container">
                <div
                  className={`color-selector-circle color-denimm ${
                    selectedColor === "#2E5EAA" ? "selected-color" : ""
                  }`}
                  onClick={() => handleColorSelect("#2E5EAA")}
                />
                <div
                  className={`color-selector-circle color-blue ${
                    selectedColor === "#0FA3B1" ? "selected-color" : ""
                  }`}
                  onClick={() => handleColorSelect("#0FA3B1")}
                />
                <div
                  className={`color-selector-circle color-gamboge ${
                    selectedColor === "#EFA00B" ? "selected-color" : ""
                  }`}
                  onClick={() => handleColorSelect("#EFA00B")}
                />
                <div
                  className={`color-selector-circle color-orange ${
                    selectedColor === "#F86624" ? "selected-color" : ""
                  }`}
                  onClick={() => handleColorSelect("#F86624")}
                />
                <div
                  className={`color-selector-circle color-red ${
                    selectedColor === "#A63A50" ? "selected-color" : ""
                  }`}
                  onClick={() => handleColorSelect("#A63A50")}
                />
              </div>
            </Form.Group>
            <Form.Group
              className="form-margin-bottom"
              controlId="customTagInput"
            >
              <Form.Label>
                <span className="sub-event-title">{tagTitle}</span>
              </Form.Label>
              <div className="custom-tag-buttons-container ">
                <Button
                  variant={`outline-secondary custom-tag-button ${
                    customTag === "School" ? "clicked" : ""
                  }`}
                  onClick={() => {
                    handleCustomTagChange({ target: { value: "School" } });
                    handleDefaultTagClick();
                    setActiveTag("School");
                  }}
                >
                  School
                </Button>
                <Button
                  variant={`outline-secondary custom-tag-button ${
                    customTag === "Work" ? "clicked" : ""
                  }`}
                  onClick={() => {
                    handleCustomTagChange({ target: { value: "Work" } });
                    handleDefaultTagClick();
                    setActiveTag("Work");
                  }}
                >
                  Work
                </Button>
                <Button
                  variant={`outline-secondary custom-tag-button ${
                    customTag === "Private" ? "clicked" : ""
                  }`}
                  onClick={() => {
                    handleCustomTagChange({ target: { value: "Private" } });
                    handleDefaultTagClick();
                    setActiveTag("Private");
                  }}
                >
                  Private
                </Button>
                <Button
                  variant={`outline-secondary custom-tag-button ${
                    activeTag === "Custom" ? "clicked" : ""
                  }`}
                  onClick={() => {
                    clearCustomTagInput();
                    setActiveTag("Custom");
                  }}
                >
                  Custom
                </Button>
              </div>

              <Form.Control
                type="text"
                value={customTag}
                onChange={handleCustomTagChange}
                maxLength={25}
                className="form-control"
                placeholder={
                  activeTag === "Custom" ? "Enter tag" : "Choose tag"
                }
                disabled={activeTag !== "Custom"}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        {/* W stopce modalu renderuje przycisk do usuwania wydarzenia jeśli editStatus jest true. */}
        <Modal.Footer className="modal-top-bot-font">
          {/* for creating  new event */}
          {!editStatus && (
            <Button
              className="custom-button"
              variant="success"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          )}

          {/* for editing created event  */}
          {/* Renderuje przycisk do zapisywania nowego wydarzenia jeśli editStatus jest false. */}
          {editStatus && (
            <Button
              className="custom-button-edit"
              variant="success"
              onClick={handleEdited}
            >
              Save Changes
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        showModal={showConfirmationModal}
        handleClose={closeConfirmationModal}
        handleConfirm={confirmDelete}
      />
    </>
  );
}

export default MyModal;
