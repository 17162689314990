import Logo from "assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="bg-white border-gray-200 px-4 py-2 p-1">
      <div className=" flex flex-wrap items-center justify-between mx-auto">
        <a href="/" className="flex items-center">
          <img src={Logo} alt="logo" className="text-xl h-8" />
          <span className="self-center text-2xl font-mulish font-bold  whitespace-nowrap text-[#005D66]">
            AIOFS
          </span>
        </a>
        <div className="items-center justify-between w-full md:flex md:w-auto md:order-1">
          <ul className="flex items-center font-anek font-medium gap-3 p-4  md:p-0 mt-4 mr-2  rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0">
            <li>
              <a
                href="/"
                className="block py-2 pl-3 pr-4 text-[#0FA3B1]  md:hover:text-[#005D66] md:p-0"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="block py-2 pl-3 pr-4 text-[#0FA3B1] hover:text-[#005D66]  md:hover:text-[#005D66] md:p-0 "
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="/register"
                className="block py-2 pl-3 pr-4 text-[#0FA3B1] hover:text-[#005D66]  md:hover:text-[#005D66] md:p-0 "
              >
                Sign up
              </a>
            </li>
            <div>
              <button
                onClick={() => navigate("/login")}
                className="bg-[#0FA3B1] text-white px-4 py-2 rounded-lg hover:text-[#005D66] focus:outline-none focus:ring-2 focus:ring-[#0FA3B1] focus:ring-opacity-50"
              >
                Log in
              </button>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
