import React, { useState,useRef,useEffect} from "react";
import "./Note.css";
import { useNavigate } from "react-router-dom";

// Komponent funkcyjny Note
const Note = ({ note, onEdit, onDelete,currentNote,setCurrentNote,setIsShareModalOpen,handleNoteClick }) => {
  
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [boardType, setBoardType] = useState("add");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openMenuNoteId, setOpenMenuNoteId] = useState(null); // Track the open ellipsis menu's note ID
  const elipsisMenuRef = useRef();
  useOutsideClick(elipsisMenuRef, () => setIsElipsisMenuOpen(false));

  const setOpenEditModal = () => {
    
    setIsElipsisMenuOpen(false);
  };
  const setOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  const handleToggleElipsisMenu = () => {
    setBoardType("edit");
    setIsElipsisMenuOpen((prevState) => !prevState);
    setOpenMenuNoteId(note.id); // Set the currently open ellipsis menu's note ID
    setCurrentNote(note); 
  };
  // Funkcja do pobierania załączonych plików
  //Funkcja umożliwia pobieranie załączonych plików. Jeśli notatka ma załączony plik, funkcja tworzy obiekt Blob z pliku, generuje tymczasowy URL do pliku, tworzy element <a> z ustawionym URL i nazwą pliku, symuluje kliknięcie, aby pobrać plik, a następnie unieważnia URL.
  const downloadFile = () => {
    if (note.file) {
      const blob = new Blob([note.file], { type: note.file.type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = note.file.name || "download";
      a.click();
      URL.revokeObjectURL(url);
    }
  };
  // Renderowanie podglądu pliku
  //Funkcja odpowiada za renderowanie podglądu pliku w zależności od jego typu. Jeśli plik jest obrazem, renderuje obraz. Dla innych typów plików, takich jak PDF lub Word, funkcja renderuje nazwę pliku. Jeśli notatka nie ma załączonych plików, funkcja zwraca null.
  const renderFilePreview = () => {
    if (note.file) {
      const fileType = note.file.type;
      const blob = new Blob([note.file], { type: fileType });
      const url = URL.createObjectURL(blob);

      if (fileType.startsWith("image")) {
        return <img src={url} alt="preview" width="100" />;
      } else if (
        fileType === "application/pdf" ||
        fileType === "application/msword" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        return <p>{note.file.name}</p>;
      }
    }
    return null;
  };
  const navigate = useNavigate();


  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  // Renderowanie UI komponentu Note
  return (
   
    <div className="note shadow-[#54367e1a] shadow-lg hover:text-[#0FA3B1] cursor-pointer "onClick={() => handleNoteClick(note)}>
        
        
       
      <div className="note-section ">
        
     
         <div style={{ display: 'inline-block',  width: '100%' }}>
      
            
            <h3 class="font-inter font-semibold" style={{textAlign: 'left', fontWeight:'bold',fontSize:'30px' }}>{note.name}
            </h3>
            </div>
          
          
         
        
      </div>
      <div className="note-section " style={{height:'150px', width: '309px',textAlign: 'left'}}>
      
        <p class="note-description " style={{marginTop:"5px",color:'black'}}>{truncateText(note.description,100)}</p>
      </div>
      <div className="note-section">
       
        {renderFilePreview()}
      </div>
      {note.file && <button onClick={downloadFile}>Download File</button>}
      <div className="note-section" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
      
      
        <p class="font-inter font-semibold" style={{ marginTop:"5px", marginRight: '8px' ,color:'#005D66' }}>{note.tag}</p>
      </div>
   
      
      

      


    </div>
   
  );
};

export default Note;
