import React, { useState } from "react";
import DeleteModal from "./DeleteModal";

function ElipsisMenu({ type, onEdit, onDelete, setOpenEditModal,setIsNotePreviewModalOpen}) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleteModalVisible(true);
  };

  return (
    <div className={type === "Notes" ? "absolute" : "absolute"}>
      <div className="flex justify-end items-center">
        <div className="w-50 text-sm text-left z-50 font-inter font-medium shadow-md shadow-[#364e7e1a] bg-white space-y-4 py-5 px-4  rounded-lg h-auto pr-12">
          <p
            onClick={() => {
              onEdit();
              setOpenEditModal();
              setIsNotePreviewModalOpen(false);
            }}
            className="cursor-pointer text-gray-700"
          >
            Edit {type}
          </p>
          <p onClick={handleDeleteClick} className="cursor-pointer "style={{color:"#E10505"}}>
            Delete {type}
          </p>
         
       
        </div>
      </div>
      {isDeleteModalVisible && (
        <DeleteModal
          type={type}
          onDelete={() => {
            onDelete();
            setIsDeleteModalVisible(false);
            setIsNotePreviewModalOpen(false);
          }}
          setIsDeleteModalOpen={setIsDeleteModalVisible}
        />
      
      )}

      
    </div>
  );
}

export default ElipsisMenu;
