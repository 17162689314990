import React, { useState } from "react";
import crossIcon from "assets/icon-cross.svg";
// import boardsSlice from "redux/boardsSlice";



function AddEditCategoryModal({ setIsCategoryModalOpen, type, createCategory, editCategory,currentCategory,handleCancelClick }) {
 
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(true);
  

  if (type === "edit" && isFirstLoad) {

  
    setIsFirstLoad(false);
  }

  const validate = () => {
    setIsValid(false);
    if (!name.trim()) {
      return false;
    }
    setIsValid(true);
    return true;
  };
  
  const onSubmit = () => {
    if (type === "add") {
      createCategory(name);
    } else {
      editCategory(name);
    }
    setIsCategoryModalOpen(false);
  };

  return (
    <div
      className="  fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown "
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        setIsCategoryModalOpen(false);
      }}
    >
      <div
        className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  bg-white  text-black font-bold
       shadow-md shadow-[#364e7e1a] max-w-md mx-auto my-auto w-full px-8  py-8 rounded-xl"
      >
      
       

        <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{display: 'inline-block',marginRight:'auto',fontSize:"24px",color:"#0FA3B1"}}>{type === "edit" ? "Edit" : "Add New"} Category</h2>
              
               <img
                src={crossIcon}
                alt="cross icon"
                onClick={handleCancelClick}
                className=" m-4  w-4 h-4 cursor-pointer "
                style={{marginRight:'0'}}
              />
            </div>






        <div className="mt-8 flex flex-col space-y-1">
          <label className="text-left  text-xl text-[#0FA3B1]">Category Name</label>
          <input
  className="bg-transparent px-4 py-2 rounded-md text-sm border-[0.5px] border-gray-500 focus:outline-[#0FA3B1] outline-1 ring-0"
  placeholder="e.g Web Design"
  value={type === "edit" && !name ? currentCategory.name : name}
  onChange={(e) => setName(e.target.value)}
  id="board-name-input"
/>
        </div>

        <div className="mt-8 flex flex-col space-y-3">
          <div>
            <button
              onClick={() => {
                const isValid = validate();
                if (isValid === true) onSubmit();
              }}
              className=" w-full items-center hover:opacity-70  mt-8 relative  text-white bg-[#0FA3B1] py-2 rounded-full"
            >
              {type === "add" ? "Create New Category" : "Save Changes"}
            </button>
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default AddEditCategoryModal;