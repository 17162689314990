import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddEditBoardModal from "./modals/AddEditBoardModal";
import Column from "./Column";
import EmptyBoard from "./EmptyBoard";
import Sidebar from "./Sidebar";
import arrowRight from "assets/arrow-right.svg";
import arrowLeft from "assets/arrow-left.svg";

function Home({ setIsTaskModalOpen, setNewTaskColIndex }) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const columns = board ? board.columns : [];

  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const scrollContainerRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 250;
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 250;
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  document.addEventListener("keydown", function (event) {
    if (event.key === "ArrowRight") {
      handleScrollRight();
    } else if (event.key === "ArrowLeft") {
      handleScrollLeft();
    }
  });

  return (
    <div
      className={`bg-[#f4f7fd] flex flex-col ${
        windowSize[0] >= 768 && isSideBarOpen ? "ml-[20rem]" : ""
      } h-screen`}
    >
      {windowSize[0] >= 768 && (
        <Sidebar
          setIsBoardModalOpen={setIsBoardModalOpen}
          isBoardModalOpen={isBoardModalOpen}
          isSideBarOpen={isSideBarOpen}
          setIsSideBarOpen={setIsSideBarOpen}
        />
      )}

      <div ref={scrollContainerRef} className=" overflow-x-hidden gap-6 flex">
        {columns.length > 0 ? (
          columns.map((col, index) => (
            <Column
              setIsTaskModalOpen={setIsTaskModalOpen}
              setNewTaskColIndex={setNewTaskColIndex}
              key={index}
              colIndex={index}
            />
          ))
        ) : (
          <EmptyBoard type="edit" />
        )}
      </div>

      {isBoardModalOpen && (
        <AddEditBoardModal
          type="edit"
          setIsBoardModalOpen={setIsBoardModalOpen}
        />
      )}

      {/* Scroll Buttons */}
      <div className="fixed bottom-4 right-4 flex items-center space-x-2">
        <img
          src={arrowLeft}
          onClick={handleScrollLeft}
          className="w-10 h-10 p-1 rounded-full bg-white text-[#0FA3B1] border border-custom-blue  flex items-center justify-center shadow-lg"
          aria-label="Scroll left"
        ></img>
        <img
          src={arrowRight}
          onClick={handleScrollRight}
          className="w-10 h-10 p-1 rounded-full bg-white text-[#0FA3B1] border border-custom-blue  flex items-center justify-center shadow-lg"
          aria-label="Scroll right"
        ></img>
      </div>
    </div>
  );
}

export default Home;
