import React, { useState, useEffect } from "react";
import Navbar from "components/landingPage/Navbar";
import Footer from "components/landingPage/Footer";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleRegisterClick = () => {
    navigate("/register");
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageAspectRatio = 3300 / 1700; // wysokość, szerokość obrazka
  const buttonPositionPercentageTop = 14;
  const buttonPositionPercentageLeft = 22;

  const buttonPositionTop =
    (buttonPositionPercentageTop / 100) * window.innerWidth * imageAspectRatio;
  const buttonPositionLeft =
    (buttonPositionPercentageLeft / 100) * window.innerWidth;

  const buttonWidth = windowWidth * 0.13;
  const buttonHeight = buttonWidth * 0.18;

  return (
    <>
      <Navbar />
      <div
        className="content-center bg-no-repeat bg-contain z-40 relative"
        style={{
          backgroundImage: "url('/assets/landingPage/screen1-5.svg')",
          height: `calc(( 3000/1700 ) * ${windowWidth}px)`,
        }}
      >
        <button
          className="button cursor-pointer bg-[#0FA3B1] text-white rounded-lg px-10 py-2 font-anek 2xl:rounded-xl  2xl:text-3xl xl:text-base lg:px-5 lg:text-[0.7rem] lg:leading-[0.8rem] md:text-xs md:py-0 md:rounded-md md:px-1  font-medium"
          style={{
            position: "absolute",
            top: `${buttonPositionTop}px`,
            left: `${buttonPositionLeft}px`,
            width: `${buttonWidth}px`,
            height: `${buttonHeight}px`,
            zIndex: 50,
          }}
          onClick={handleRegisterClick}
        >
          Get Started
        </button>
      </div>
      <div className="relative h-0 flex justify-center top-[-230px] z-50 ">
        <button
          className="button absolute bg-[#0FA3B1] text-white text-3xl rounded-xl 2xl:text-2xl 2xl:py-3 2xl:top-[80px] 2xl:px-28 xl:top-[90px] xl:py-1 xl:px-16 lg:text-2xl lg:px-12 lg:top-[135px] lg:py-1 py-2 md:text-xl md:top-[160px] md:px-8 md:py-1 font-anek font-medium"
          onClick={handleRegisterClick}
        >
          Start now!
        </button>
      </div>
      <Footer />
    </>
  );
}

export default App;
