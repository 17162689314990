import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../Auth";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";
import Logo from "assets/icon-logo.svg";
import { toast } from "react-toastify";

function Register() {
  const TURNSTILE_SITE = process.env.REACT_APP_TURNSTILE_SITE;
  const navigate = useNavigate();
  const { login } = useAuth();
  const turnstile = useTurnstile();
  const [formData, setFormData] = useState({
    nickname: "",
    email: "",
    password: "",
    repeatPassword: "",
    cfTurnstile: "",
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const handleCaptchaVerify = (cfTurnstile) => {
    setFormData((prevState) => ({ ...prevState, cfTurnstile }));
    //console.log(`verif ${cfTurnstile}`);
  };

  const handleCaptchaExpired = () => {
    turnstile.reset();
    //console.log("Captcha expired. Resetting...");
  };
  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (jwt) {
      navigate("/dashboard");
    }
  }, [navigate]);

  function sanitize(e) {
    if (e.target.name === "email") {
      return e.target.value.toLowerCase();
    }
    if (e.target.name === "nickname") {
      return e.target.value.toLowerCase().replace(/[^a-z0-9._]/g, "");
    }
    return e.target.value;
  }
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: sanitize(e),
    });
  };
  const handleAgreeTermsChange = (e) => {
    setAgreeTerms(e.target.checked);
  };
  const handleRegister = async (e) => {
    handleCaptchaExpired();
    e.preventDefault();
    if (!agreeTerms) {
      toast.error("You must agree to the terms of use", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (formData.password !== formData.repeatPassword) {
      toast.error("Passwords do not match", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (formData.nickname.length < 3 || formData.nickname.length > 15) {
      toast.error(
        "Please choose a nickname that is between 3 and 15 characters in length.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      return;
    }
    try {
      const registrationPromise = axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        formData
      );
      toast.promise(registrationPromise, {
        pending: {
          render({ data }) {
            return `Registering...`;
          },
        },
        success: {
          render({ data }) {
            return `Registration successful!`;
          },
          autoClose: 3000,
        },
        error: {
          render({ data }) {
            return `Registration failed`;
          },
        },
      });
      const response = await registrationPromise;
      if (response.data.token) {
        login(response.data.token);
        await new Promise((resolve) => setTimeout(resolve, 1000)); //temporary mozilla cookie creation fix
        window.location.reload();
      } else {
        alert("Registration failed");
      }
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white px-4 py-2 p-1">
      <header className="w-full flex justify-between items-center">
        <a href="/">
          <div className="flex items-center ">
            <img
              src={Logo}
              alt="Logo"
              className=" text-xl h-8"
              onClick={() => navigate("/")}
            />
            <span className="text-2xl font-mulish font-bold text-[#005D66]">
              AIOFS
            </span>
          </div>
        </a>
        <button
          onClick={() => navigate("/")}
          className="bg-[#0FA3B1] text-white px-4 py-2 mr-2 rounded-lg hover:bg-[#0FA3B1] focus:outline-none focus:ring-2 focus:ring-[#0FA3B1] focus:ring-opacity-50 font-anek"
        >
          Dismiss
        </button>
      </header>

      <div className="flex-1 flex items-start justify-center p-7 mt-20">
        <div className="p-8">
          <h2 className="text-6xl font-mulish font-bold text-[#005D66] mb-14 text-center">
            Sign Up
          </h2>
          <form onSubmit={handleRegister}>
            {/* Email */}
            <div className="mb-3 w-96">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Enter email address..."
              />
            </div>
            {/* Nickname */}
            <div className="mb-3">
              <input
                type="text"
                name="nickname"
                value={formData.nickname}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Enter nickname..."
              />
            </div>
            {/* Password */}
            <div className="mb-3">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Enter password..."
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-[#0FA3B1] placeholder-gray-500 bg-[#F2F5FB]"
                placeholder="Repeat password..."
              />
            </div>
            {/* Agree to Terms */}
            <div className="mb-6 flex items-center">
              <input
                type="checkbox"
                checked={agreeTerms}
                onChange={handleAgreeTermsChange}
                className="w-4 h-4 text-[#0FA3B1] border-gray-300 rounded focus:ring-[#0FA3B1]"
              />
              <label className="ml-2 text-sm font-normal text-[#005D66]">
                I agree to{" "}
                <Link className="underline" to="/terms-of-use">
                  Terms of Use
                </Link>
              </label>
            </div>
            <Turnstile
              sitekey={TURNSTILE_SITE}
              onVerify={handleCaptchaVerify}
              autoResetOnExpire={true}
            />
            {/* Submit Button */}
            <button
              type="submit"
              className="w-full px-4 py-2 bg-[#0FA3B1] text-white text-xl rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 font-anek font-medium"
            >
              Sign up
            </button>
          </form>
          <p className="mt-4 text-sm text-[#005D66]">
            Already have an account?{" "}
            <Link to="/login" className="text-[#005D66] underline">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
