import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const jwt = cookies.jwt;
    if (jwt && !user) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
          headers: { Authorization: `Bearer ${jwt}` },
        })
        .then((response) => {
          setUser(response.data.user);
          setLoading(false);
          // Check if the token is about to expire (e.g., within 5 minutes).
          const tokenExpirationTime = decodeTokenExpirationTime(jwt); // Implement this function to decode the token's expiration time.
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds.
          const timeToExpiration = tokenExpirationTime - currentTime;

          if (timeToExpiration < 300) {
            // Token is about to expire in less than 5 minutes, renew it.
            //renewToken();
          }
        })
        .catch((error) => {
          console.error('Error verifying token:', error);
          removeCookie('jwt');
          setLoading(false);
        });
    }
    else {
      setLoading(false);
    }
  }, [cookies.jwt, user]);

  const decodeTokenExpirationTime = (token) => {
    try {
      //const decoded = jwt.decode(token);
      const decoded = false
      if (!decoded || !decoded.exp) {
        return null;
      }
      return decoded.exp;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

/*
  const renewToken = () => {
    // Send a request to your server to generate a new JWT token.
    axios
      .post('http://localhost:5000/api/auth/renew', {})
      .then((response) => {
        const newJwt = response.data.newJwt;
        setCookie('jwt', newJwt, { path: '/' });
      })
      .catch((error) => {
        console.error('Error renewing token:', error);
        // Handle token renewal failure gracefully.
      });
  };
*/
  const login = (jwt) => {
    const expirationDate=new Date(Date.now()+36000000)//10h
    setCookie('jwt', jwt, { path: '/', expires: expirationDate, secure: true });
  };

  const logout = () => {
    removeCookie('jwt');
    setUser(null);
    window.location.replace('/');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
