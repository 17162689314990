import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import boardIcon from "assets/icon-board-star-grey.svg";
import showSidebarIcon from "assets/icon-show-sidebar.svg";
import hideSidebarIcon from "assets/icon-hide-sidebar.svg";
import boardIconBlue from "assets/icon-board-star-blue.svg";
import linkIconGrey from "assets/icon-link-grey.svg";
import boardsSlice from "./redux/boardsSlice";
import AddEditBoardModal from "./modals/AddEditBoardModal";
import boardIconWhite from "assets/icon-star-white.svg";
import linkIconWhite from "assets/icon-share-white.svg";

function Sidebar({ isSideBarOpen, setIsSideBarOpen }) {
  const dispatch = useDispatch();
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);

  const boards = useSelector((state) => state.boards);

  const toggleSidebar = () => {
    setIsSideBarOpen((curr) => !curr);
  };

  return (
    <div>
      <div
        className={
          isSideBarOpen
            ? ` min-w-[260px] max-w-[22rem] bg-white fixed top-[48px] h-screen items-center left-0 z-20 text-ellipsis`
            : `bg-[#0FA3B1]  top-auto bottom-10  justify-center items-center hover:opacity-80 cursor-pointer p-0 transition duration-300 transform fixed w-[3.5rem] h-[3rem] rounded-r-full text-ellipsis`
        }
      >
        <div>
          {/* reWrite modal  */}
          {isSideBarOpen && (
            <div className="bg-white w-full rounded-xl">
              <h3 className="text-gray-500 font-inter font-medium text-base mx-4 mt-20 mb-2">
                All boards ({boards?.length})
              </h3>

              <div className="flex flex-col justify-between ">
                <div className="overflow-y-auto h-auto max-h-[380px] max-w-[300px] ">
                  {boards.map((board, index) => (
                    <div
                      className={`flex items-baseline space-x-2 px-5 mr-8 rounded-r-full duration-500 ease-in-out py-3 cursor-pointer  ${
                        board.isActive
                          ? "bg-[#0FA3B1] rounded-r-full text-white mr-8"
                          : ""
                      }`}
                      key={index}
                      onClick={() => {
                        dispatch(boardsSlice.actions.setBoardActive({ index }));
                      }}
                    >
                      <img
                        src={board.isActive ? boardIconWhite : boardIcon}
                        alt="board icon"
                        className="h-4"
                      />
                      <p className="text-lg font-inter font-medium truncate">
                        {board.name}
                      </p>
                      {board.sharedUsers.length !== 0 && (
                        <img
                          src={board.isActive ? linkIconWhite : linkIconGrey}
                          className="h-4"
                          alt="share icon"
                        />
                      )}
                    </div>
                  ))}
                </div>

                <div
                  className="flex items-baseline space-x-2 mr-8 rounded-r-full duration-500 ease-in-out cursor-pointer text-[#0FA3B1] px-5 py-3 hover:bg-[#635fc71a] hover:text-[#0FA3B1]"
                  onClick={() => {
                    setIsBoardModalOpen(true);
                  }}
                >
                  <img
                    src={boardIconBlue}
                    alt="board icon blue"
                    className="h-4"
                  />
                  <p className="text-lg font-anek font-medium">+ New Board</p>
                </div>
              </div>
            </div>
          )}

          {/* Sidebar hide/show toggle */}
          {isSideBarOpen ? (
            <div
              onClick={() => toggleSidebar()}
              className="flex items-center absolute bottom-16 text-lg font-anek font-medium rounded-r-full cursor-pointer mr-6 mb-8 px-8 py-3 space-x-2 justify-center my-4 text-[#0FA3B1]"
            >
              <img
                className="min-w-[1.25rem] w-7 h-7 mb-[2px]"
                src={hideSidebarIcon}
                alt="side bar show/hide"
              />
              {isSideBarOpen && <p>Hide sidebar</p>}
            </div>
          ) : (
            <div
              className="absolute p-5 pb-4 pt-4"
              onClick={() => toggleSidebar()}
            >
              <img src={showSidebarIcon} alt="showSidebarIcon" />
            </div>
          )}
        </div>
      </div>
      {/* New board modal */}
      {isBoardModalOpen && (
        <AddEditBoardModal
          type="add"
          setIsBoardModalOpen={setIsBoardModalOpen}
        />
      )}
    </div>
  );
}

export default Sidebar;
