import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Home from "./Home";
import EmptyBoard from "./EmptyBoard";
import boardsSlice from "./redux/boardsSlice";
import Navbar from "components/AppsNavbar";

function App() {
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [newTaskColIndex, setNewTaskColIndex] = useState(false);
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const activeBoard = boards.find((board) => board.isActive);

  if (!activeBoard && boards.length > 0)
    dispatch(boardsSlice.actions.setBoardActive({ index: 0 }));

  return (
    <div>
      <div className="sticky top-0 z-50">
        <Navbar />
        <Header
          setIsBoardModalOpen={setIsBoardModalOpen}
          isBoardModalOpen={isBoardModalOpen}
          setIsTaskModalOpen={setIsTaskModalOpen}
          isTaskModalOpen={isTaskModalOpen}
          newTaskColIndex={newTaskColIndex}
          setNewTaskColIndex={setNewTaskColIndex}
        />
      </div>
      <div>
        {boards.length > 0 ? (
          <Home
            setIsBoardModalOpen={setIsBoardModalOpen}
            isBoardModalOpen={isBoardModalOpen}
            setIsTaskModalOpen={setIsTaskModalOpen}
            isTaskModalOpen={isTaskModalOpen}
            setNewTaskColIndex={setNewTaskColIndex}
          />
        ) : (
          <EmptyBoard type="add" />
        )}
      </div>
    </div>
  );
}

export default App;
