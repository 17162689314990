import React,{useState,useEffect,useRef} from "react";
import elipsis from "assets/icon-vertical-ellipsis.svg";
import ElipsisMenu from "./ElipsisMenu";
const NotePreviewModal = ({ note, onClose,setIsNotePreviewModalOpen,setCurrentNote,currentNote,onEdit,onDelete }) => {
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [boardType, setBoardType] = useState("add");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openMenuNoteId, setOpenMenuNoteId] = useState(null); // Track the open ellipsis menu's note ID
  const elipsisMenuRef = useRef();
  useOutsideClick(elipsisMenuRef, () => setIsElipsisMenuOpen(false));

  const setOpenEditModal = () => {
    
    setIsElipsisMenuOpen(false);
  };
  const setOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  const handleToggleElipsisMenu = () => {
    setBoardType("edit");
    setIsElipsisMenuOpen((prevState) => !prevState);
    setOpenMenuNoteId(note.id); // Set the currently open ellipsis menu's note ID
    setCurrentNote(note); 
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  function useOutsideClick(ref, callback) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  }
  // Add modal styling and layout
  return (
    <div className="fixed right-0 top-0 px-2 py-4 overflow-scroll scrollbar-hide  z-50 left-0 bottom-0 justify-center items-center flex dropdown"
    
    onClick={(e) => {
      if (e.target !== e.currentTarget) {
        return;
      }
      setIsNotePreviewModalOpen(false);
    }}
    
    >

        <div class=" max-h-[95vh]  bg-white   h-92 shadow-md shadow-[#364e7e1a] max-w-md mx-auto my-auto w-full px-8  
          py-8 rounded-xl   ">


        <div style={{display:"flex"}}>
      <h3 class="text-[#0FA3B1] text-3xl  font-inter font-semibold" style={{textAlign:'left',marginRight:"auto"}}>{note?.name}</h3>
      <img
            onClick={handleToggleElipsisMenu}
            src={elipsis}
            alt="elipsis"
            className=" cursor-pointer h-6 "
          style={{float:"right"}}
          />
          </div>
      <div style={{  float: 'right' , marginRight:'170px'}}>
      {isElipsisMenuOpen && openMenuNoteId === note.id && (
        <div ref={elipsisMenuRef}>
             <ElipsisMenu
             type="note"
             onEdit={() => onEdit(note)}
             onDelete={() => onDelete(note.id)}
             setOpenEditModal={setOpenEditModal}
             setOpenDeleteModal={setOpenDeleteModal}
           setIsNotePreviewModalOpen={setIsNotePreviewModalOpen}
            
             currentNote={currentNote}
             setCurrentNote={setCurrentNote}
           />
           </div>
          )}
            </div>
      <p class="mt-8 text-[#0FA3B1]  font-inter font-semibold" style={{textAlign:'left',marginRight:"auto"}}>Description</p>
      <p class=" note-preview-description text-black text-left  font-inter font-regular"style={{wordWrap: 'break-word'}}>{(note.description)}</p>
      <p class="mt-4 text-[#0FA3B1] font-inter font-semibold" style={{textAlign:'left',marginRight:"auto"}}>Tag </p>
      <p class="text-black text-left font-inter">{note?.tag}</p>
      {/* Add more note details as needed */}
     
      </div>
    </div>
  );
};

export default NotePreviewModal;