import { Hourglass } from "react-loader-spinner";

function Loading() {
  return (
    <div className="min-h-screen flex flex-col bg-white justify-center items-center">
      <Hourglass type="TailSpin" color="#0FA3B1" height={100} width={100} />
      <span className="pt-5 text-lg text-[#0FA3B1]">
        <strong>If page loading takes too long, refresh.</strong>
      </span>
    </div>
  );
}

export default Loading;
