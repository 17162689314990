import React, { useState, useEffect } from "react";
import iconDown from "assets/icon-chevron-down.svg";
import iconUp from "assets/icon-chevron-up.svg";

function PermissionPopup({
  user,
  handleEditPermission,
  handleDeleteFromShared,
  board,
  sharedUser,
}) {
  const [showPopup, setShowPopup] = useState(false);

  // Funkcja do ukrywania popupa po kliknięciu poza nim
  useEffect(() => {
    const closePopup = (e) => {
      if (showPopup && !e.target.closest(".permission-popup")) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", closePopup);
    return () => document.removeEventListener("mousedown", closePopup);
  }, [showPopup]);

  return (
    <div className="relative inline-block ">
      <button
        className="rounded border-gray-300 text-gray-400 mx-2 p-1 font-xs flex items-center "
        onClick={() => setShowPopup(!showPopup)}
      >
        {sharedUser.canEdit ? "Can edit" : "Can view"}
        {showPopup ? (
          <img
            className="ml-2 filter-gray-400 fill-gray-300 mt-[6px] "
            src={iconUp}
            alt="iconUp"
          />
        ) : (
          <img
            className="ml-2 filter-gray-400 fill-gray-300 mt-[3px]"
            src={iconDown}
            alt="iconDown"
          />
        )}
      </button>
      {showPopup && (
        <div className="permission-popup absolute right-0 mt-2 w-44 bg-white shadow-lg rounded-lg z-50 space-y-4 py-5 px-4 pr-2 font-inter font-sm ">
          <ul className="text-gray-700">
            {user._id != board.owner._id ? (
              <li
                className="p-2 text-red-500 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  handleDeleteFromShared(user._id);
                  setShowPopup(false);
                }}
              >
                Delete access
              </li>
            ) : (
              <>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer font-inter font-sm"
                  onClick={() => {
                    handleEditPermission(sharedUser.user._id, true);
                    setShowPopup(false);
                  }}
                >
                  Can edit
                </li>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer font-inter font-sm"
                  onClick={() => {
                    handleEditPermission(sharedUser.user._id, false);
                    setShowPopup(false);
                  }}
                >
                  Can view
                </li>
                <li
                  className="p-2 text-red-500 hover:bg-gray-100 cursor-pointer font-inter"
                  onClick={() => {
                    handleDeleteFromShared(sharedUser.user._id);
                    setShowPopup(false);
                  }}
                >
                  Delete access
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PermissionPopup;
