import { useState } from "react";
import { useAuth } from "Auth";
import Logo from "assets/logo.svg";
import LogoCalendar from "assets/icon-calendar.svg";
import LogoNotes from "assets/icon-notes.svg";
import LogoTodo from "assets/icon-todo.svg";
import Loading from "components/Loading";
import AvatarIcon from "assets/icon-account-white-bg.svg";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const { user } = useAuth();
  if (!user) {
    return <Loading />;
  }
  return (
    <nav className="bg-gray-100 shadow-black text-neutral-50">
      <div className="flex flex-wrap items-center justify-between p-1">
        <a href="/dashboard" className="flex items-center">
          <img
            src={Logo}
            alt="logo-dashboard"
            className="h-8 mr-1 text-4xl mx-4"
          />
          <span className="self-center text-2xl font-mulish font-bold whitespace-nowrap text-[#005D66]">
            AIOFS
          </span>
        </a>
        <div className="items-center justify-end w-full md:flex md:w-auto md:order-1 mr-4">
          <ul className="flex flex-row font-medium p-4 gap-3 md:p-0 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 md:justify-end mx-2">
            <li>
              <a
                href="/calendar"
                title="Calendar"
                className="block py-2 pl-3 pr-4 rounded"
              >
                <img
                  src={LogoCalendar}
                  alt="logo-calendar"
                  className="h-6 w-6"
                />
              </a>
            </li>
            <li>
              <a href="/todo" className="block py-2 pl-3 pr-4 rounded">
                <img
                  src={LogoTodo}
                  alt="logo-todo"
                  title="To do"
                  className="h-6 w-6 "
                />
              </a>
            </li>
            <li>
              <a href="/notes" className="block py-2 pl-3 pr-4 rounded">
                <img
                  src={LogoNotes}
                  alt="logo-notes"
                  title="Notes"
                  className="h-6 w-6 "
                />
              </a>
            </li>
          </ul>
          <div className="relative flex flex-row" data-te-dropdown-ref>
            <button
              className="flex mr-4 ml-7 text-sm rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300"
              href="#"
              type="button"
              data-te-dropdown-toggle-ref
              aria-expanded={showMenu}
              data-te-ripple-init
              data-te-ripple-color="light"
              onClick={toggleMenu}
              title="Profile"
            >
              <span className="sr-only">Open user menu</span>
              <img
                src={user.avatar ? user.avatar : AvatarIcon}
                alt="user pic"
                className="w-8 h-8 rounded-full bg-gray-100"
              />
            </button>
            {showMenu ? (
              <ul
                className=" top-10 right-0.5 absolute z-[1000] float-left min-w-max list-none overflow-wrap rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg [&[data-te-dropdown-show]]:block"
                data-te-dropdown-menu-ref
              >
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400"
                    href="/dashboard/settings"
                    data-te-dropdown-item-ref
                  >
                    Profile settings
                  </a>
                </li>
                {/* Add the Rate Us  */}
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline"
                    href="https://m1ylqfbhcaw.typeform.com/to/SFy2yyL7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Feedback
                  </a>
                </li>
                {/* Add the Report Bug */}
                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline"
                    href="/report-bug"
                  >
                    Report a Bug
                  </a>
                </li>

                <li>
                  <a
                    className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal  text-red-500 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400"
                    href="/"
                    onClick={handleLogout}
                  >
                    Log out
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
