import React, { useState } from "react";
import Navbar from "components/landingPage/Navbar";
import Footer from "components/landingPage/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

function App() {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
    file: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();

    const dataToSend = new FormData();
    dataToSend.append("email", formData.email);
    dataToSend.append("message", formData.message);
    dataToSend.append("subject", formData.subject);
    if (formData.file) {
      dataToSend.append("file", formData.file);
    }
    console.log(formData.file);
    console.log("Dane do formData:", formData);
    console.log(dataToSend);
    // Reset formularza

    try {
      const jwt = Cookies.get("jwt");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contact`,
        dataToSend,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );

      // Reset form
      setFormData({
        email: "",
        subject: "",
        message: "",
        file: null,
      });
      toast.info(response.data.msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-white ">
        <div className="container mx-auto p-4 flex justify-center items-center h-screen">
          <div className="w-full max-w-md p-6">
            <h2 className="text-6xl mb-9 text-center text-[#005D66] font-mulish font-bold">
              Contact Us
            </h2>
            <form onSubmit={handleSend} encType="multipart/form-data">
              {/* Email field */}
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-xl  mb-2 text-[#0fa3b1] font-inter font-regular"
                >
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
                  placeholder="Enter email address"
                />
              </div>

              {/* Subject field */}
              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="block text-xl font-regular mb-2 text-[#0fa3b1]"
                >
                  Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
                  placeholder="Enter subject"
                />
              </div>

              {/* Message field */}
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-xl  mb-2 text-[#0fa3b1] font-inter font-regular "
                >
                  Your Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  rows="4"
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
                  placeholder="Enter your message"
                />
              </div>

              {/* File field */}
              <div className="mb-4">
                <label
                  htmlFor="file"
                  className="block text-xl mb-2 text-[#0fa3b1] font-inter font-regular"
                >
                  Attachment (optional)
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                  className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-[#F2F5FB]"
                />
              </div>

              {/* Submit button */}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-[#0fa3b1] hover:bg-[#0b8c9e] text-white  py-2  px-16 rounded-lg focus:outline-none focus:shadow-outline mb-3 mt-4 font-anek font-semibold"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
