import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import elipsis from "assets/icon-vertical-ellipsis.svg";
import ConfirmationModal from "./ConfirmationDeleteModal";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};

const EventViewModal = ({
  show,
  handleClose: originalHandleClose,
  event,
  onEdit,
  handleDelete,
  setShowEventViewModal,
}) => {
  const [showElipsisMenu, setShowElipsisMenu] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  if (!event) {
    return null;
  }

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    handleDelete(); // Wywołanie faktycznego usunięcia
    setShowConfirmationModal(false);
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleClose = () => {
    setShowElipsisMenu(false);
    originalHandleClose();
  };

  const toggleElipsisMenu = (e) => {
    // Prevents the modal from closing when the elipsis menu is clicked
    e.stopPropagation();
    setShowElipsisMenu(!showElipsisMenu);
  };

  const handleEditClick = (e) => {
    // Prevents the modal from closing when the edit is clicked
    e.stopPropagation();
    setShowElipsisMenu(false);
    setShowEventViewModal(false);
    onEdit(event);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className="my-modal ">
        <Modal.Header className="sub-event-title modal-header">
          <div className="header-container">
            <h1 className="sub-event-title-h1 ">{event.title}</h1>
            <div className="header-actions">
              <div>
                <img
                  alt="elipsis icon"
                  src={elipsis}
                  onClick={toggleElipsisMenu}
                  className="elipsis-icon"
                />
                {showElipsisMenu && (
                  <div className="elipsis-menu py-5 px-4">
                    <div className="menu-item" onClick={handleEditClick}>
                      Edit Event
                    </div>
                    <div
                      className="menu-item-delete"
                      onClick={handleDeleteClick}
                    >
                      Delete Event
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="modal-body-scrollable ">
          <div className="breaker">
            {event.description && (
              <p>
                <strong className="sub-event-title ">Description</strong>{" "}
                {event.description}
              </p>
            )}
            <p>
              <strong className="sub-event-title ">Start Event</strong>{" "}
              {formatDate(event.start)}
            </p>
            <p>
              <strong className="sub-event-title ">End Event</strong>{" "}
              {formatDate(event.end)}
            </p>
            {event.tag && (
              <p>
                <strong className="sub-event-title">Tag</strong> {event.tag}
              </p>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        showModal={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        handleConfirm={confirmDelete}
      />
    </>
  );
};

export default EventViewModal;
