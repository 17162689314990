import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSubtaskCompletedAction } from "./redux/boardsActions";

function Subtask({ index, taskIndex, colIndex }) {
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const col = board.columns.find((col, i) => i === colIndex);
  const task = col.tasks.find((task, i) => i === taskIndex);
  const subtask = task.subtasks.find((subtask, i) => i === index);
  const checked = subtask.isCompleted;

  const onChange = (e) => {
    dispatch(setSubtaskCompletedAction({ index, taskIndex, colIndex }));
  };

  //dodanie unikalnego id do kazdego checkboxa
  const checkboxId = `subtask-checkbox-${colIndex}-${taskIndex}-${index}`;

  return (
    <div className=" w-full flex hover:bg-[#635fc740] rounded-md relative items-center justify-start  p-3 gap-4  bg-[#f4f7fd] ">
      <input
        id={checkboxId}
        className=" w-4 h-4  accent-[#0FA3B1] cursor-pointer "
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      {/* powiązanie ID z inputem poprzez htmlFor */}
      <label htmlFor={checkboxId} className="cursor-pointer ">
        <p
          className={
            checked ? "line-through opacity-30 font-inter font-semibold" : ""
          }
        >
          {subtask.title}
        </p>
      </label>
    </div>
  );
}

export default Subtask;
