import Logo from "assets/icon-logo.svg";
import arrowFooter from "assets/arrow-landing-page-footer.svg";

const Footer = () => {
  return (
    <footer className="bg-[#F2FBFF] py-10 mt-[-10px]">
      {" "}
      {/* Increased the vertical padding */}
      <div className="ml-20 mr-10 mx-auto flex flex-col items-center justify-between space-y-6 lg:space-y-0  lg:flex-row lg:items-center ">
        <div className="flex items-center space-x-2">
          {" "}
          {/* Logo and Arrow */}
          <img src={Logo} alt="AIOFS logo" className="h-10" />
          <span className="text-3xl lg:text-3xl md:text-3xl sm:text-2xl font-mulish font-bold text-[#005159] mt-1">
            AIOFS
          </span>
        </div>

        <div className="flex justify-center space-x-10 font-inter font-regular">
          {" "}
          {/* Two columns of links */}
          <div className="flex flex-col items-center mr-10  ">
            <a href="/" className="py-2 text-[#0Fa3b1]">
              Home
            </a>
            <a href="/terms-of-use" className="py-2 text-[#0Fa3b1]">
              Terms of use
            </a>
          </div>
          <div className=" ml-10 flex flex-col items-center ">
            <a href="/contact" className="py-2 text-[#0Fa3b1]">
              Contact us
            </a>
            <a href="/register" className="py-2 text-[#0Fa3b1]">
              Sign up
            </a>
          </div>
          <a href="#top" className="text-2xl pl-10 mt-3 lg:pr-10 ">
            <img src={arrowFooter} alt="Arrow Up" width={60} height={60} />
          </a>
        </div>
      </div>
      <div className="bg-[#0Fa3b1] mt-4 absolute w-full text-white">
        {" "}
        {/* Subfooter */}
        <div className="max-w-screen-xl mx-auto p-4 flex flex-col lg:flex-row justify-between items-center text-sm font-inter font-regular">
          <span>©2024 AIOFS - All rights reserved</span>
          <span>We don't share or sell your personal information</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
