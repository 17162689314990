import React, { useState, useRef, useEffect } from "react";
import todoBlueIcon from "assets/icon-todo-blue.svg";
import elipsis from "assets/icon-vertical-ellipsis.svg";
import ElipsisMenu from "./ElipsisMenu";
import AddEditTaskModal from "./modals/AddEditTaskModal";
import AddEditBoardModal from "./modals/AddEditBoardModal";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "./modals/DeleteModal";
import { deleteBoardAction } from "./redux/boardsActions";
import iconDown from "assets/icon-chevron-down.svg";
import iconUp from "assets/arrow-chevron-up.svg";

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

function Header({
  setIsBoardModalOpen,
  isBoardModalOpen,
  setIsTaskModalOpen,
  isTaskModalOpen,
  newTaskColIndex,
  setNewTaskColIndex,
}) {
  const [isElipsisMenuOpen, setIsElipsisMenuOpen] = useState(false);
  const [boardType, setBoardType] = useState("add");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMiniModalOpen, setIsMiniModalOpen] = useState(false);

  const dispatch = useDispatch();

  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive);
  const [isMd, setIsMd] = useState(
    window.matchMedia("(min-width: 900px)").matches
  );

  const elipsisMenuRef = useRef();
  useOutsideClick(elipsisMenuRef, () => setIsElipsisMenuOpen(false));

  //edit board const for + New Column button
  const openEditBoardModal = () => {
    setBoardType("edit");
    setIsBoardModalOpen(true);
  };

  const setOpenEditModal = () => {
    setIsBoardModalOpen(true);
    setIsElipsisMenuOpen(false);
  };
  const setOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setIsElipsisMenuOpen(false);
  };

  const onDeleteBtnClick = (e, boardData) => {
    if (e.target.textContent === "Delete") {
      dispatch(deleteBoardAction(boardData));
      setIsDeleteModalOpen(false);
    } else {
      setIsDeleteModalOpen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 900px)");
    const handleMediaChange = (e) => setIsMd(e.matches);

    mediaQuery.addListener(handleMediaChange);

    // Wywołanie początkowe
    handleMediaChange(mediaQuery);

    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  return (
    <div className="p-2 sticky top-2rem left-0 bg-white z-50 right-0 shadow-[#54367e1a] shadow-md">
      <header className="flex justify-between items-center">
        {/* Lewa strona */}
        <div className="flex items-center space-x-10 md:space-x-4">
          <img src={todoBlueIcon} alt="Logo" className="h-7 w-7" />
          <h3 className="md:text-4xl hidden md:inline-block  text-[#0FA3B1] font-anek font-regular">
            To do
          </h3>

          {boards && board && (
            <div className="flex items-center">
              <h3 className="truncate  max-w-[600px] md:max-w-[350px] md:text-2xl text-xl font-anek font-semibold ml-[170px] text-[#0FA3B1]">
                {board.name}
              </h3>
            </div>
          )}
        </div>

        {/* Prawa strona */}
        {boards && board && (
          <div className="flex space-x-4 items-center mr-5 md:space-x-6 relative">
            {!isMd ? (
              // Renderowanie ikon, kiedy nie jesteśmy w zakresie `md`
              <img
                src={isMiniModalOpen ? iconUp : iconDown}
                onClick={() => setIsMiniModalOpen(!isMiniModalOpen)}
                alt="Toggle Mini Modal"
                className="cursor-pointer h-5 w-5 fill-gray-500"
              />
            ) : (
              // Renderowanie przycisków, kiedy jesteśmy w zakresie `md`
              <>
                <button
                  className="button bg-white text-[#0FA3B1] border border-custom-blue rounded-xl font-anek font-medium py-2 px-4"
                  onClick={openEditBoardModal}
                >
                  + New Column
                </button>

                <button
                  className="button bg-[#0FA3B1] text-white rounded-xl font-anek font-medium py-2 px-4"
                  onClick={() => setIsTaskModalOpen((prevState) => !prevState)}
                >
                  + New Task
                </button>
              </>
            )}

            <img
              onClick={() => {
                setBoardType("edit");
                setIsElipsisMenuOpen((prevState) => !prevState);
              }}
              src={elipsis}
              alt="elipsis"
              className="cursor-pointer h-6"
              title="Edit/share/delete board"
            />
            {isElipsisMenuOpen && (
              <div ref={elipsisMenuRef}>
                <ElipsisMenu
                  type="Board"
                  setOpenEditModal={setOpenEditModal}
                  setOpenDeleteModal={setOpenDeleteModal}
                />
              </div>
            )}
          </div>
        )}
      </header>
      {isMiniModalOpen && !isMd && (
        <div className="absolute top-full right-0 translate-x-[-10%] translate-y-1 z-50">
          <div className="bg-white rounded-lg shadow-md shadow-[#364e7e1a] overflow-hidden">
            <div className="space-y-2 py-3 px-4 font-anek font-semibold">
              <p
                onClick={openEditBoardModal}
                className="cursor-pointer text-gray-600 hover:bg-gray-100 p-2 rounded"
              >
                + New Column
              </p>
              <p
                onClick={() => setIsTaskModalOpen(true)}
                className="cursor-pointer text-gray-600 hover:bg-gray-100 p-2 rounded"
              >
                + New Task
              </p>
            </div>
          </div>
        </div>
      )}

      {isTaskModalOpen && (
        <AddEditTaskModal
          setIsAddTaskModalOpen={setIsTaskModalOpen}
          setNewTaskColIndex={setNewTaskColIndex}
          newTaskColIndex={newTaskColIndex}
          type="add"
          device="mobile"
        />
      )}
      {isBoardModalOpen && (
        <AddEditBoardModal
          setBoardType={setBoardType}
          type={boardType}
          setIsBoardModalOpen={setIsBoardModalOpen}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          type="board"
          title={board?.name}
          onDeleteBtnClick={onDeleteBtnClick}
        />
      )}
    </div>
  );
}

export default Header;
