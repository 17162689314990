import React, { useState } from "react";
import ShareBoardModal from "./modals/ShareBoardModal";

function ElipsisMenu({ type, setOpenEditModal, setOpenDeleteModal }) {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  return (
    <>
      <div
        className={
          type === "Board"
            ? "fixed right-5 top-28 z-50"
            : "absolute top-6 right-4"
        }
      >
        <div className="flex justify-end items-center">
          <div className="w-50 text-sm z-50 font-inter font-medium shadow-md shadow-[#364e7e1a] bg-white space-y-4 py-5 px-4 rounded-lg h-auto pr-12">
            <p
              onClick={() => {
                setOpenEditModal();
              }}
              className="cursor-pointer text-gray-700"
            >
              Edit {type}
            </p>
            {type === "Task" ? null : (
              <p
                onClick={() => setIsShareModalOpen(true)}
                className="cursor-pointer text-gray-700"
              >
                Share board
              </p>
            )}
            <p
              onClick={() => setOpenDeleteModal()}
              className="cursor-pointer text-red-500"
            >
              Delete {type}
            </p>
          </div>
        </div>
      </div>

      {isShareModalOpen && (
        <ShareBoardModal setIsShareModalOpen={setIsShareModalOpen} />
      )}
    </>
  );
}

export default ElipsisMenu;
