import React, { useState } from "react";
import showSidebarIcon from "assets/icon-show-sidebar.svg";
import hideSidebarIcon from "assets/icon-hide-sidebar.svg";
import boardIcon from "assets/icon-board-star-grey.svg";
import boardIconWhite from "assets/icon-star-white.svg";
import linkIconGray from "assets/icon-link-grey.svg";
import boardIconBlue from "assets/icon-board-star-blue.svg";
import linkIconWhite from "assets/icon-share-white.svg";

function Sidebar({
  isSideBarOpen,
  setIsSideBarOpen,
  categories,
  setIsEditing,
  setIsCategoryModalOpen,
  selectCategory,
}) {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleSidebar = () => {
    setIsSideBarOpen((curr) => !curr);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }

  const handleSelectCategory = (category) => {
    selectCategory(category);
    setActiveCategory(category ? category.name : "All Notes");
  };

  return (
    <div className="mt-[20.5px] text-align-center">
      <div
        className={
          isSideBarOpen
            ? `min-w-[260px] max-w-[22rem] bg-white fixed top-[48px] py-14 h-screen  items-center left-0 z-5`
            : ` bg-[#0FA3B1]  top-auto bottom-10 justify-center items-center hover:opacity-80 cursor-pointer  p-0 transition duration-300 transform fixed w-[56px] h-[48px] rounded-r-full  `
        }
      >
        <div>
          {isSideBarOpen && (
            <div className=" bg-white w-full py-4 rounded-xl">
              <h3
                className="  text-gray-500 font-inter font-medium text-base mx-4 mb-4 mt-[3px] text-align-left "
                style={{ textAlign: "left" }}
              >
                All categories ({categories?.length})
              </h3>

              <div className=" flex flex-col h-[70vh]  justify-between ">
                <div>
                  <div className="overflow-y-auto h-auto max-h-[380px] max-w-[300px] ">
                  <div
                    className={`flex items-baseline space-x-2 px-5 mr-8 rounded-r-full duration-500 ease-in-out py-4 cursor-pointer ${
                      activeCategory === "All Notes" ? "bg-[#0FA3B1]" : "hover:bg-[#f4f7fd] hover:text-[#0FA3B1]"
                    }`}
                    onClick={() => handleSelectCategory(null)}
                  >
                    <img
                      src={activeCategory === "All Notes" ? boardIconWhite : boardIcon}
                      alt="board icon"
                      className="h-4"
                    />
                    <p className={`text-lg font-inter font-medium ${
                      activeCategory === "All Notes" ? "text-white" : "text-[#34495e]"
                    }`}>
                      {"All Notes"}
                    </p>
                  </div>
                    {categories.map((category, index) => (
                      <div
                        className={` flex items-baseline space-x-2 px-5 mr-8 rounded-r-full font-inter font-medium duration-500 ease-in-out py-4 cursor-pointer text-[#34495e] hover:bg-[#f4f7fd] hover:text-gray-500  ${
                          category.isActive &&
                          " bg-[#0FA3B1] rounded-r-full text-white mr-8"
                        } `}
                        key={index}
                        onClick={() => handleSelectCategory(category)}
                      >
                        <img
                          src={category.isActive ? boardIconWhite : boardIcon}
                          alt="board icon white"
                          className="h-4 "
                        />
                        <p className="text-lg">
                          {truncateText(category.name, 20)}
                        </p>
                        {category.sharedUsers.length !== 0 ? (
                          <img
                            src={
                              category.isActive ? linkIconWhite : linkIconGray
                            }
                            alt="link-icon"
                            className="icon w-4 h-4"
                          ></img>
                        ) : null}
                      </div>
                    ))}
                  </div>

                  <div
                    className=" flex  items-baseline space-x-2  mr-8 rounded-r-full duration-500 ease-in-out cursor-pointer text-[#0FA3B1] px-5 py-4 hover:bg-[#f4f7fd] hover:text-[#0FA3B1]   "
                    onClick={() => {
                      setIsCategoryModalOpen(true);
                      setIsEditing(false);
                    }}
                  >
                    <img
                      src={boardIconBlue}
                      alt="board icon blue"
                      className="h-4 "
                    />
                    <p className=" flex text-lg font-anek font-medium  ">
                      + New Category{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isSideBarOpen ? (
            <div
              onClick={() => toggleSidebar()}
              className=" flex items-center mt-2 absolute bottom-16 text-lg font-anek font-medium rounded-r-full cursor-pointer mr-6 mb-8 px-8 py-4 space-x-2 justify-center my-4 text-[#0FA3B1] "
            >
              <img
                className=" min-w-[20px] h-7 w-7"
                src={hideSidebarIcon}
                alt=" side bar show/hide"
              />
              {isSideBarOpen && <p> Hide Sidebar </p>}
            </div>
          ) : (
            <div className=" absolute p-5  " onClick={() => toggleSidebar()}>
              <img src={showSidebarIcon} alt="showSidebarIcon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
