import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/Calendar.css";

const ConfirmationModal = ({ showModal, handleClose, handleConfirm }) => {
  return (
    <Modal show={showModal} onHide={handleClose} centered className="my-modal ">
      <Modal.Header>
        <Modal.Title className=" sub-event-title-confirm-delete sub-event-title-h1 ">
          Confirm Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirm-modal-body">
        Are you sure you want to delete this event?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={handleConfirm}
          className="custom-button-delete "
        >
          Delete
        </Button>
        <Button
          variant="secondary"
          className="custom-button-cancel"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
