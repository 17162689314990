import Navbar from "components/landingPage/Navbar";
import Footer from "components/landingPage/Footer";

function App() {
  return (
    <>
      <Navbar />
      <div
        className="content-center bg-no-repeat bg-cover h-screen"
        style={{ backgroundImage: `url('/assets/subpage-bg.png')` }}
      ></div>
      <Footer />
    </>
  );
}

export default App;
