import React, { useState, useEffect } from "react";
import crossIcon from "assets/icon-cross.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";
import PermissionPopup from "components/todo/PermissionPopup";
import { replaceBoardAction } from "../redux/boardsActions";
import AvatarIcon from "assets/icon-account-white-bg.svg";
import { useAuth } from "Auth";
import { toast } from "react-toastify";

function ShareBoardModal({ setIsShareModalOpen }) {
  const { user } = useAuth();
  const [nickname, setNickname] = useState("");
  const [shareWith, setShareWith] = useState([]);
  const [userNotFound, setUserNotFound] = useState(false); // 'user not found' error

  // Fetching boards from Redux store and finding the active board
  const dispatch = useDispatch();
  const boards = useSelector((state) => state.boards);
  const activeBoard = boards.find((board) => board.isActive);
  useEffect(() => {
    setShareWith(activeBoard.sharedUsers);
  }, [activeBoard, user._id]);

  // Sprawdzenie, czy użytkownik może edytować tablicę
  const canEditBoard =
    activeBoard.owner._id === user._id ||
    activeBoard.sharedUsers.some(
      (sharedUser) => sharedUser.user._id === user._id && sharedUser.canEdit
    );

  const handleDeleteFromShared = async (user) => {
    if (!canEditBoard && user._id !== user.nickname) {
      console.error("You can only remove yourself.");
      return;
    }
    const data = {
      sharedUser: user,
      documentId: activeBoard._id,
    };
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/share/board`,

      {
        headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        data,
      }
    );
    const updatedSharedUsers = activeBoard.sharedUsers.filter(
      (sharedUser) => sharedUser.user._id != user
    );
    dispatch(
      replaceBoardAction({
        ...activeBoard,
        sharedUsers: updatedSharedUsers,
      })
    );
  };
  const handleEditPermission = async (user, canEdit) => {
    try {
      const data = {
        sharedUser: user, //nickname usera do zmiany uprawnień
        documentId: activeBoard._id,
        canEdit: canEdit, //uprawnienie (true/false) może czy nie może edytować
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/share/board`,
        data,
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      );
      console.log(response.data);
      dispatch(replaceBoardAction(response.data));
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        return setUserNotFound(true);
      }
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };
  const handleShare = async () => {
    try {
      const data = {
        nickname: nickname,
        documentId: activeBoard._id,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/share/board`,
        data,
        {
          headers: { Authorization: `Bearer ${Cookies.get("jwt")}` },
        }
      );
      dispatch(replaceBoardAction(response.data));
    } catch (error) {
      if (error.response?.status === 404) {
        return setUserNotFound(true);
      }
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-gray-700 bg-opacity-50">
      <div className="bg-white rounded-xl shadow-lg max-w-md w-full p-8 text-gray-400 ">
        <div className="flex justify-between items-center">
          <div className="font-anek font-semibold">
            <h3 className="text-xl text-[#0FA3B1] ">Share Board</h3>
            <div className="text-xl text-[#0FA3B1] break-all">
              "{activeBoard?.name}"
            </div>
          </div>
          <img
            src={crossIcon}
            alt="close"
            onClick={() => setIsShareModalOpen(false)}
            className="cursor-pointer"
          />
        </div>

        <div className="mt-3">
          <label className="text-base text-[#0FA3B1] font-inter font-medium block mb-2">
            Choose user
          </label>
          <div className="flex gap-3 items-center">
            <input
              className="w-full p-2 border border-gray-500 rounded-md flex-grow"
              placeholder="Enter nickname"
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
                setUserNotFound(false);
              }}
            />
            <button
              className="bg-[#0FA3B1] text-white rounded-xl px-4 py-2 border border-custom-blue hover:bg-[#0FA3B1] transition-colors font-anek font-medium"
              onClick={handleShare}
            >
              Share
            </button>
          </div>
          {userNotFound && <p className="text-red-500">*User not found*</p>}
        </div>
        <div className="mt-4">
          <h4 className="text-base text-[#0FA3B1] font-inter font-medium">
            Users with access
          </h4>
          <div className="flex items-center justify-between mt-2 ">
              <img
                src={activeBoard.owner.avatar ? activeBoard.owner.avatar : AvatarIcon}
                className="w-8 h-8 rounded-full mt-2 mb-2"
                alt="owner avatar"
              />
            <span className=" ml-2 flex-1 font-inter font-medium font-xs text-gray-400">
              {activeBoard?.owner.nickname}
            </span>
            <div className="mr-8 font-xs text-gray-400">Owner</div>
          </div>
          {shareWith.map((sharedUser, index) => (
            <div key={index} className="flex items-center justify-between  ">
              <span>
                <img
                  src={sharedUser.user.avatar ? sharedUser.user.avatar : AvatarIcon}
                  alt="user pic"
                  className="w-8 h-8 rounded-full mt-2"
                />
              </span>
              <span className="flex-1 ml-2">{sharedUser.user.nickname}</span>
              {user._id != activeBoard.owner._id &&
              sharedUser.user._id != user._id ? (
                <div className="relative inline-block ">
                  <button className="rounded border-gray-300 text-gray-400 mx-2 p-1 font-xs flex items-center font-inter font-medium">
                    {sharedUser.canEdit ? "Can edit" : "Can view"}
                  </button>
                </div>
              ) : (
                <>
                  <PermissionPopup
                    user={user}
                    sharedUser={sharedUser}
                    index={index}
                    handleEditPermission={handleEditPermission}
                    handleDeleteFromShared={handleDeleteFromShared}
                    board={activeBoard}
                  />
                </>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-center space-x-4 mt-4 ">
          <button
            className="bg-white text-[#0FA3B1] rounded-xl px-20 py-2 border border-custom-blue hover:bg-[#0FA3B1] hover:text-white transition-colors font-anek font-medium"
            onClick={() => setIsShareModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
export default ShareBoardModal;
