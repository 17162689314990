import React from "react";
import "./App.css";

function DeleteModal({ type, onDelete, setIsDeleteModalOpen }) {
  return (
    // Modal Container
    <div
      onClick={(e) => {
        if (e.target !== e.currentTarget) {
          return;
        }
        setIsDeleteModalOpen(false);
      }}
      className="modal"
    >
      {/* Delete Modal  */}

      <div className=" scrollbar-hide overflow-y-scroll max-h-[95vh]  my-auto  bg-white  text-black dark:text-white font-bold shadow-md shadow-[#364e7e1a] max-w-md mx-auto  w-full px-8  py-8 rounded-xl ">
        <h3 className=" font-anek  text-xl text-left " style={{color:"#E10505"}}>
          Delete this {type}?
        </h3>
        {type === "note" ? (
          <p className="text-gray-500 font-[600] tracking-wide text-xs pt-6 text-left">
            Are you sure you want to delete the note?
          </p>
        ) : (
          <p className="text-gray-500 font-[600] tracking-wide text-xs pt-6 text-left">
            Are you sure you want to delete the category?
          </p>
        )}

        <div className=" flex w-full mt-4 items-center justify-center space-x-4 ">
          <button
            onClick={onDelete}
            className="w-full items-center text-white font-anek hover:opacity-75 bg-red-500 py-2 "style={{backgroundColor:"#E10505",borderColor:"#E10505", borderRadius:"20px", borderWidth:"2px"}}
          >
            Delete
          </button>
          <button
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
            className="w-full items-center text-[#0FA3B1] font-anek hover:opacity-75   py-2   " style={{borderColor:"#0FA3B1", borderRadius:"20px", borderWidth:"2px"}}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
