import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dragTaskAction } from "./redux/boardsActions";
import Task from "./Task";

function Column({ colIndex, setIsTaskModalOpen, setNewTaskColIndex }) {
  const colors = [
    "bg-red-500",
    "bg-orange-500",
    "bg-yellow-500",
    "bg-sky-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-pink-500",
    "bg-purple-500",
    "bg-indigo-500",
  ];

  const dispatch = useDispatch();
  const [color, setColor] = useState(null);
  const boards = useSelector((state) => state.boards);
  const board = boards.find((board) => board.isActive === true);
  const col = board.columns.find((col, i) => i === colIndex);

  // color set circles in tables
  useEffect(() => {
    const colorIndex = colIndex % colors.length;
    setColor(colors[colorIndex]);
  }, [colIndex]);

  const handleOnDrop = (e) => {
    const { prevColIndex, taskIndex } = JSON.parse(
      e.dataTransfer.getData("text")
    );

    if (colIndex !== prevColIndex) {
      dispatch(dragTaskAction({ colIndex, prevColIndex, taskIndex }));
    }
  };

  const handleOnDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={handleOnDrop}
      onDragOver={handleOnDragOver}
      className="ml-4 pt-3 min-w-[300px] z-1"
    >
      <div className="text-sm break-words font-inter font-semibold flex  items-center  gap-2 tracking-widest md:tracking-[.2em] text-gray-500">
        <div className={`rounded-full w-4 h-4 ${color} `} />
        {col.name} ({col.tasks.length})
      </div>

      {col.tasks.length === 0 ? (
        <button
          className="w-[280px] my-5 rounded-lg bg-white shadow-[#54367e1a] py-6 shadow-lg text-[#0FA3B1] cursor-pointer font-inter font-semibold text-center"
          onClick={() => {
            setIsTaskModalOpen(true);
            setNewTaskColIndex(colIndex);
          }}
        >
          +New Task
        </button>
      ) : (
        col.tasks.map((task, index) => (
          <Task key={index} taskIndex={index} colIndex={colIndex} />
        ))
      )}
    </div>
  );
}

export default Column;
