import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState, useEffect } from "react";
import MyModal from "./modals/MyModal";
import axios from "axios";
import Cookies from "js-cookie";
import iconCalendarBlue from "assets/icon-calendar-blue.svg";
import { FiSearch } from "react-icons/fi";
import EventViewModal from "./modals/EventViewModal";
import { useAuth } from "Auth";
import Loading from "components/Loading";
import linkIconBlue from "assets/icon-link-blue.svg";
import { toast } from "react-toastify";
import ManagementCalendars from "./ManagementCalendars";

//dnd calendar
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

// Ustawienie poniedziałka jako pierwszego dnia tygodnia dla `moment`
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd, D MMMM YYYY HH:mm",
  },
});

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CustomAgendaEvent = ({ event }) => (
  <div className="my-agenda-event">
    <strong>{event.title}</strong>
    {event.tag && <span> ( Tag: {event.tag} )</span>}
  </div>
);

const CalendarComponent = () => {
  const { user } = useAuth();

  const jwt = Cookies.get("jwt");
  const [events, setEvents] = useState([]);
  const [customTag, setCustomTag] = useState("");

  // {sharowanie i inne rzeczy potrzebne do nowego działania kalendarza}
  const [calendarSpecificEvents, setCalendarSpecificEvents] = useState([]);

  const [activeCalendar, setActiveCalendar] = useState(undefined);

  // koniec sharowanie

  //states for creating event
  const [modalStatus, setModalStatus] = useState(false);
  //state for on select event/modal states
  const [eventId, setEventId] = useState("");
  const [editStatus, setEditStatus] = useState(false);
  const [eventInput, setEventInput] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // description
  const [eventDescription, setEventDescription] = useState("");
  //zmiana koloru
  const [selectedColor, setSelectedColor] = useState("");

  // Nowy stan dla wyszukiwania tagów
  const [searchTag, setSearchTag] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // Nowy stan dla widoczności pola wyszukiwania

  //state for modal validation
  const [errors, setErrors] = useState({
    eventTitle: false,
    customTag: false,
    description: false,
  });

  // Na początku komponentu BasicCalendar
  const [showEventViewModal, setShowEventViewModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Funkcja do otwierania modalu podglądu
  const handleEventClick = (event) => {
    setEventId(event.id);
    setSelectedEvent(event);
    setShowEventViewModal(true);
  };

  //error modal validation
  const validateEvent = () => {
    let validationErrors = {};

    if (!eventInput || eventInput.trim() === "") {
      validationErrors.eventTitle = "*You have to write something here*";
    }
    if (!selectedColor) {
      validationErrors.selectedColor = "*You must select a color*";
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Zwraca true jeśli nie ma błędów
  };

  //Zamyka modal i czyści stan.
  // zamknięcie modalu i zresetowanie stanu modalu do wartości domyślnych. Ustawia wartość showModal na false, co powoduje zamknięcie modalu, a także czyści wartość eventInput, aby użytkownik mógł zacząć wpisywać od nowa, gdy ponownie otworzy modal.
  const handleClose = () => {
    setModalStatus(false);
    setEventInput("");
    setCustomTag("");
    setEventDescription("");
    setEditStatus(false); //reset stanu edycji po zamknięciu edycji modala
    setShowEventViewModal(false);
  };

  //Aktualizacja stanu z wartościami wprowadzanymi przez użytkownika.
  const handleChange = (e) => {
    setEventInput(e.target.value);
  };

  const handleEventDeleted = () => {
    setShowEventViewModal(false);
  };

  //Aktualizacja stanu z wartościami wprowadzanymi przez użytkownika.
  const handleCustomTagChange = (e) => {
    setCustomTag(e.target.value); // Aktualizacja stanu niestandardowego tagu
  };

  const handleSave = async () => {
    if (validateEvent()) {
      setModalStatus(false);
      if (eventInput) {
        try {
          const newEvent = {
            id: Date.now(),
            title: `${eventInput}`,
            start: new Date(`${startDate}`),
            end: new Date(`${endDate}`),
            tag: customTag,
            color: selectedColor,
            description: eventDescription,
            calendar: activeCalendar?._id,
          };

          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/calendar/event`,
            newEvent,
            {
              headers: { Authorization: `Bearer ${jwt}` },
            }
          );
          response.data.start = new Date(response.data.start);
          response.data.end = new Date(response.data.end);

          setEvents([...events, response.data]);
          handleClose();
        } catch (error) {
          console.error("Error creating event:", error);
          const err = error.response.data.err;
          if (Array.isArray(err)) {
            return error.response.data.err.forEach((e) => {
              toast.error(e.msg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            });
          } else {
            toast.error(error.response.data.err, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          }
        }
      }
      setCustomTag("");
    }
  };

  //Obsługa zdarzenia wybierania slotu, ustawiając daty początkową i końcową oraz otwierając modal.
  const handleSlotSelectEvent = (slotInfo) => {
    setStartDate(new Date(`${slotInfo.start}`));
    setEndDate(new Date(`${slotInfo.end}`));
    setModalStatus(true);
    setEventInput("");
  };

  const moveEventHandler = async ({ event, start, end }) => {
    const eventObj = events.find((obj) => obj.id === event.id);
    try {
      eventObj.start = start;
      eventObj.end = end;

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/calendar/event`,
        eventObj,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      response.data.start = new Date(response.data.start);
      response.data.end = new Date(response.data.end);
      const updatedEvents = events.map((event) =>
        event.id === eventId ? response.data : event
      );
      setEvents(updatedEvents);
      handleClose();
    } catch (error) {
      console.error("Error updating event:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const resizeEventHandler = async ({ event, start, end }) => {
    const eventObj = events.find((obj) => obj.id === event.id);
    try {
      eventObj.start = start;
      eventObj.end = end;

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/calendar/event`,
        eventObj,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      response.data.start = new Date(response.data.start);
      response.data.end = new Date(response.data.end);
      const updatedEvents = events.map((event) =>
        event.id === eventId ? response.data : event
      );
      setEvents(updatedEvents);
      handleClose();
    } catch (error) {
      console.error("Error updating event:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  //Ustawienie stanu, aby edytować wybrane wydarzenie i otwieranie modala.
  const hanldeOnSelectEvent = (e) => {
    setSelectedColor(e.color);
    setEditStatus(true);
    setStartDate(new Date(`${e.start}`));
    setEndDate(new Date(`${e.end}`));
    setEventInput(e.title);
    setEventId(e.id);
    setModalStatus(true);
    setEventDescription(e.description || "");
    setCustomTag(e.tag || "");
  };

  //Edycja wybranego wydarzenia, a następnie aktualizacja listy wydarzeń.
  const handleEditEvent = (e) => {
    setEventInput(e.target.value);
  };

  const handleEdited = async (e) => {
    setModalStatus(false);
    let updatedEvents = [];
    if (eventInput) {
      const eventObj = events.find((obj) => obj.id === `${eventId}`);
      try {
        eventObj.id = `${eventId}`;
        eventObj.title = `${eventInput}`;
        eventObj.start = new Date(`${startDate}`);
        eventObj.end = new Date(`${endDate}`);
        eventObj.color = selectedColor;
        eventObj.description = eventDescription;
        eventObj.tag = customTag;
        eventObj.calendar = activeCalendar?._id;

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/calendar/event`,
          eventObj,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        response.data.start = new Date(response.data.start);
        response.data.end = new Date(response.data.end);
        const updatedEvents = events.map((event) =>
          event.id === eventObj.id ? eventObj : event
        );
        setEvents(updatedEvents);
        handleClose();
      } catch (error) {
        console.error("Error updating event:", error);
        const err = error.response.data.err;
        if (Array.isArray(err)) {
          return error.response.data.err.forEach((e) => {
            toast.error(e.msg, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
        } else {
          toast.error(error.response.data.err, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    } else {
      updatedEvents = events.filter((e) => {
        return e.id !== eventId;
      });
      setEvents([...updatedEvents]);
    }
    setEditStatus(false);
    // setEventInput("");
  };

  const handleDelete = async () => {
    try {
      const eventObj = events.find((obj) => obj.id === eventId);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/calendar/event`,
        {
          data: { _id: eventObj._id },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      const updatedEvents = events.filter((event) => event.id !== eventId);
      setEvents(updatedEvents);
    } catch (error) {
      console.error("Error deleting event:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    setModalStatus(false);
    setEventInput("");
    handleEventDeleted();
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/calendar/event`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      response.data.forEach((event) => {
        event.start = new Date(event.start);
        event.end = new Date(event.end);
      });
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      const err = error.response.data.err;
      if (Array.isArray(err)) {
        return error.response.data.err.forEach((e) => {
          toast.error(e.msg, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
      } else {
        toast.error(error.response.data.err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color || undefined; //to zmienione bo błąd był że eventy utworzone przed wprowadzeniem kolorku zmieniały sie wszystkie gdy wybrano kolorek
    var style = {
      backgroundColor: backgroundColor,
      color: "white",
    };
    return {
      style: style,
    };
  };

  // Metoda do obsługi kliknięcia w lupę lub napis "Search"
  const toggleSearch = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  // Funkcja do aktualizacji wyszukiwanego tagu
  const handleSearchTagChange = (e) => {
    setSearchTag(e.target.value);
  };

  // Funkcja do filtrowania wydarzeń na podstawie tagu
  useEffect(() => {
    if (searchTag) {
      const lowerCaseSearchTag = searchTag.toLowerCase();
      setFilteredEvents(
        calendarSpecificEvents.filter(
          (event) => event.tag?.toLowerCase() === lowerCaseSearchTag
        )
      );
    } else {
      setFilteredEvents(calendarSpecificEvents);
    }
  }, [searchTag, calendarSpecificEvents]);

  // UI dla wyszukiwania tagów
  const renderTagSearch = () =>
    isSearchVisible && (
      <div className="search-box">
        <input
          type="text"
          placeholder="Search by tag..."
          value={searchTag}
          onChange={handleSearchTagChange}
          className="search-input"
        />
        <button onClick={() => setSearchTag("")}>Clear</button>
      </div>
    );

  if (!user) {
    return <Loading />;
  }

  // Logo containe + calendar list + search tag icon + others renders
  return (
    <div className="my-calendar">
      <div className="logo-container ">
        {/* Warunkowe renderowanie ikony w zależności od tego, czy kalendarz jest udostępniany */}
        {activeCalendar && activeCalendar.sharedUsers.length !== 0 ? (
          <img
            src={linkIconBlue}
            alt="link-icon"
            className="icon w-6 h-6"
          ></img>
        ) : (
          <img
            src={iconCalendarBlue}
            alt="logo-notes"
            className="icon w-6 h-6"
          ></img>
        )}

        <ManagementCalendars
          events={events}
          activeCalendar={activeCalendar}
          setActiveCalendar={setActiveCalendar}
          setCalendarSpecificEvents={setCalendarSpecificEvents}
        />

        <FiSearch className="search-icon" onClick={toggleSearch} />

        {/* Wywołanie renderTagSearch tutaj, aby pokazać pole wyszukiwania pod ikoną lupy */}
        {renderTagSearch()}
      </div>
      <DnDCalendar
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        selectable
        //event trigger after clicking any slot
        onSelectSlot={handleSlotSelectEvent}
        //event trigger after clicking any event
        // onSelectEvent={hanldeOnSelectEvent}
        onSelectEvent={handleEventClick}
        //event for drag and drop
        onEventDrop={moveEventHandler}
        //event trigger hen resizing any event
        resizable
        onEventResize={resizeEventHandler}
        // onSelecting={slot => false}
        longPressThreshold={10}
        eventPropGetter={eventStyleGetter} // zmiana koloru
        components={{
          agenda: {
            event: CustomAgendaEvent,
          },
        }}
      />
      <MyModal
        modalStatus={modalStatus}
        handleClose={handleClose}
        handleSave={handleSave}
        handleChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        eventInput={eventInput}
        handleEditEvent={handleEditEvent}
        handleEdited={handleEdited}
        editStatus={editStatus}
        handleDelete={handleDelete}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        customTag={customTag}
        handleCustomTagChange={handleCustomTagChange}
        errors={errors}
        selectedColor={selectedColor}
        handleColorSelect={handleColorSelect}
        eventDescription={eventDescription}
        handleDescriptionChange={(e) => setEventDescription(e.target.value)}
        onEventDeleted={handleEventDeleted}
      />
      <EventViewModal
        show={showEventViewModal}
        handleClose={() => setShowEventViewModal(false)}
        event={selectedEvent || {}}
        onEdit={hanldeOnSelectEvent}
        handleDelete={handleDelete}
        setShowEventViewModal={setShowEventViewModal}
      />
    </div>
  );
};

export default CalendarComponent;
